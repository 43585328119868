import { RepaymentInformation } from '@newday/plum-types';
import {
  getMockApplicant,
  getMockApplicationForGetRequest,
} from '@newday/acquisitions-api-client';

export const getMockRepaymentInformation = (
  overrides?: Partial<RepaymentInformation>
): RepaymentInformation => ({
  paymentStartDate: getMockApplicant().paymentStartDate,
  monthlyPayment: getMockApplicationForGetRequest().product.monthlyPayment,
  ...overrides,
});
