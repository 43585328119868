import {
  DependantsCount,
  EligibilityQuestions,
  EmploymentStatus,
  FinancialSituation,
  MaritalStatus,
  ResidentialStatus,
} from '@newday/plum-types';

export const getMockEligibilityQuestions = (
  overrides?: Partial<EligibilityQuestions>
): EligibilityQuestions => {
  return {
    grossAnnualIncome: 50000,
    employmentStatus: EmploymentStatus.fullTime,
    dependants: DependantsCount.two,
    otherHouseholdIncome: 10000,
    maritalStatus: MaritalStatus.cohabiting,
    residentialStatus: ResidentialStatus.ownerOccupier,
    accommodationCosts: 1000,
    councilTax: 250,
    expenditureUtilitiesCost: 200,
    expenditureEducationCost: 150,
    expenditureOtherCost: 300,
    financialSituation: FinancialSituation.staySame,
    ...overrides,
  };
};
