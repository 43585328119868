import { accordionAnatomy as parts } from '@chakra-ui/anatomy';
import type {
  PartsStyleObject,
  SystemStyleObject,
} from '@chakra-ui/theme-tools';
import { ComponentStyleConfig } from '@chakra-ui/react';
import { whiten } from '@chakra-ui/theme-tools';

const baseStyleContainer: SystemStyleObject = {
  backgroundColor: 'white',
  color: 'black',
};

const baseStyleButton: SystemStyleObject = {
  py: '1rem',
  px: '0.5rem',
  transitionProperty: 'common',
  transitionDuration: 'normal',
  fontWeight: 700,
  fontSize: 'md',
  lineHeight: 'base',
  _focus: {
    boxShadow: 'none',
  },
  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
  },
};

const baseStylePanel: SystemStyleObject = {
  px: '0.5rem',
  pb: '1.25rem',
  color: 'black',
  fontWeight: 400,
  fontSize: 'sm',
  lineHeight: 'shorter',
};

const baseStyleIcon: SystemStyleObject = {
  fontSize: '1.5rem',
  transform: 'rotate(-90deg)',
};

const baseStyle: PartsStyleObject<typeof parts> = {
  container: baseStyleContainer,
  button: baseStyleButton,
  panel: baseStylePanel,
  icon: baseStyleIcon,
};

const Accordion: ComponentStyleConfig = {
  parts: parts.keys,
  baseStyle,
  variants: {
    outline: ({ theme }) => {
      const { colors } = theme;
      return {
        ...baseStyle,
        container: {
          color: 'brand.primary',
          borderColor: 'brand.primary',
          borderWidth: '1px',
          borderRadius: 'brand.field',
          '&:not(:last-child)': {
            mb: '0.75rem',
          },
        },
        button: {
          ...baseStyleButton,
          px: '1rem',
          borderRadius: 'brand.field',
          _focus: {
            boxShadow: `0 0 0 2px ${colors['brand']['primary']}`,
            bg: whiten('brand.quaternary', 80),
          },
          _hover: {
            boxShadow: `0 0 0 2px ${colors['brand']['primary']}`,
            bg: whiten('brand.quaternary', 80),
          },
          _expanded: {
            boxShadow: 'none',
          },
        },
        panel: {
          ...baseStylePanel,
          px: '1rem',
        },
      };
    },
  },
};

const AccordionItem = {
  variants: {
    alert: {
      borderColor: 'red.600',
    },
  },
};

const AccordionButton: ComponentStyleConfig = {
  variants: {
    'default-alert': ({ theme }) => {
      const hoverColor = whiten('red.50', 50)(theme);
      return {
        backgroundColor: 'red.50',
        color: 'red.600',
        borderRadius: 'brand.field',
        _hover: {
          bg: `${hoverColor} !important`,
        },
        _expanded: {
          boxShadow: 'none',
        },
      };
    },
    'outline-alert': ({ theme }) => {
      const { colors } = theme;
      const hoverColor = whiten('red.50', 50)(theme);
      return {
        backgroundColor: 'red.50',
        color: 'red.600',
        borderRadius: 'brand.field',
        _focus: {
          boxShadow: `0 0 0 2px ${colors.red['600']} !important`,
          bg: `${hoverColor} !important`,
        },
        _hover: {
          boxShadow: `0 0 0 2px ${colors.red['600']} !important`,
          bg: `${hoverColor} !important`,
        },
        _expanded: {
          boxShadow: `none !important`,
        },
      };
    },
  },
};

const AccordionIcon = {
  variants: {
    alert: {
      color: 'red.600',
    },
  },
};

export { Accordion, AccordionButton, AccordionItem, AccordionIcon };
