import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { initializeAppInsights } from '../src/utils/app-insights';
import { App } from './app';
import { detectBrandFromUrl } from './utils/detect-brand-from-url';

declare global {
  interface Window {
    __plum_version__?: string;
  }
}

const renderApp = async () => {
  initializeAppInsights();
  setVersion();
  await loadMocks();

  const container = document.getElementById('root');
  if (!container) throw new Error("Root element not found!");
  const brand = detectBrandFromUrl(window.location.href);

  const root = createRoot(container);
  root.render(
    <StrictMode>
      <App brand={brand} />
    </StrictMode>
  );
};

declare const BUILD_VERSION;

const setVersion = () => {
  window.__plum_version__ = BUILD_VERSION;
};

const loadMocks = async () => {
  if (process.env.NODE_ENV === 'development') {
    const { worker } = await import('./mocks/browser-dev');
    worker.start();
  }
};

renderApp();
