import { AprAndRiskGroup, RiskGroup } from '@newday/plum-types';

export const getMockAprAndRiskGroup = (
  overrides?: Partial<AprAndRiskGroup>,
): AprAndRiskGroup => {
  return {
    riskGroup: RiskGroup.low,
    realRateInd: 1,
    apr: 18,
    minLoanTerm: 1,
    maxLoanTerm: 5,
    minLoanValue: 1500,
    maxLoanValue: 9000,
    loanValues: [
      {
        from: 1000,
        to: 7000,
        prices: [
          { term: 12, apr: 21 },
          { term: 24, apr: 20 },
          { term: 36, apr: 19 },
          { term: 48, apr: 19 },
          { term: 60, apr: 17 },
        ],
      },
      {
        from: 7001,
        to: 9000,
        prices: [
          { term: 12, apr: -9 },
          { term: 24, apr: -9 },
          { term: 36, apr: 16 },
          { term: 48, apr: 15 },
          { term: 60, apr: 14 },
        ],
      },
    ],
    ...overrides,
  };
};
