import { whiten } from '@chakra-ui/theme-tools';
import {
  Box,
  Divider,
  Text,
  useTheme,
  ValidationMessage,
  VStack,
} from '@newday/core';
import React from 'react';
import type {
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
} from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import {
  AccountNumberInput,
  accountNumberPattern,
} from '../account-number-input';
import { SortCodeInput, sortCodePattern } from '../sort-code-input';

export interface BoxProps {
  invalid?: boolean;
  invalidMessage?: string;
}
export type BankDetailsInputProps = BoxProps;

const BankDetailsBox: React.FC<BoxProps> = ({
  children,
  invalid,
  invalidMessage,
}) => {
  const theme = useTheme();
  const boxBg = whiten('brand.quaternary', 80)(theme);
  return (
    <>
      <VStack
        p={6}
        borderWidth="2px"
        borderColor={invalid ? 'red.600' : boxBg}
        bg={invalid ? 'red.50' : boxBg}
        justifyContent={{ md: 'center' }}
      >
        <Box w={{ md: 68 }}>{children}</Box>
      </VStack>

      {invalid && invalidMessage ? (
        <ValidationMessage
          mt={3}
          alignItems="flex-start"
          message={invalidMessage}
        />
      ) : null}
    </>
  );
};

const sortCodeRules = {
  required: 'Please enter a valid sort code',
  pattern: {
    value: sortCodePattern,
    message: 'Please enter a valid sort code',
  },
};

const SortCodeField = ({
  field: { name, value, onChange, onBlur },
  fieldState: { invalid, error },
}: {
  field: ControllerRenderProps<FieldValues, 'sort-code'>;
  fieldState: ControllerFieldState;
}) => (
  <Box mb={4}>
    <SortCodeInput
      placeholder="00-00-00"
      isInvalid={invalid}
      {...{ name, value, onChange, onBlur }}
    />
    {invalid && error?.message ? (
      <ValidationMessage message={error?.message} mt={3} />
    ) : null}
  </Box>
);

const accountNumberRules = {
  required: 'Please enter a valid account number',
  pattern: {
    value: accountNumberPattern,
    message: 'Please enter a valid account number',
  },
};

const AccountNumberField = ({
  field: { name, value, onChange, onBlur },
  fieldState: { invalid, error },
}: {
  field: ControllerRenderProps<FieldValues, 'account-number'>;
  fieldState: ControllerFieldState;
}) => (
  <Box mb={4} w="full">
    <AccountNumberInput
      placeholder="00000000"
      isInvalid={invalid}
      {...{ name, value, onChange, onBlur }}
    />
    {invalid && error?.message ? (
      <ValidationMessage message={error?.message} mt={3} />
    ) : null}
  </Box>
);

const BankDetailsInput: React.FC<BankDetailsInputProps> = ({
  invalid = false,
  invalidMessage,
}) => {
  const { control } = useFormContext();

  return (
    <fieldset>
      <Text
        as="legend"
        mb={2}
        fontSize="xl"
        color="brand.primary"
        fontWeight="bold"
      >
        Your bank details
      </Text>
      <Divider border="1px solid" borderColor="gray.200" />
      <Text my={4} fontSize="sm">
        Please enter your details carefully. We&apos;ll validate them to make
        sure we&apos;re sending your money to the right place.
      </Text>
      <BankDetailsBox invalid={invalid} invalidMessage={invalidMessage}>
        <Controller
          control={control}
          name="sort-code"
          defaultValue=""
          rules={sortCodeRules}
          render={({ field, fieldState }) => (
            <SortCodeField field={field} fieldState={fieldState} />
          )}
        />

        <Controller
          control={control}
          name="account-number"
          defaultValue=""
          rules={accountNumberRules}
          render={({ field, fieldState }) => (
            <AccountNumberField field={field} fieldState={fieldState} />
          )}
        />
      </BankDetailsBox>
    </fieldset>
  );
};

export { BankDetailsInput };
