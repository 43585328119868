import { Box, CloseButton, Heading, Text } from '@newday/core';
import { AlertSolidIcon, BinIcon, Spinner, TickSolidIcon } from '@newday/icons';
import React from 'react';
import { FileError } from 'react-dropzone';
import { MetaFile } from '../../../app/files-provider';

interface FileListItemProps {
  errors?: FileError[];
  id: string;
  isDeleting?: boolean;
  isLoading?: boolean;
  name: string;
  onDelete: (id: string) => void;
  size: number;
  submitted: boolean;
}

interface FileListProps {
  files: MetaFile[];
  handleDeleteFile: (id: string) => void;
}

const FileListItem: React.FC<FileListItemProps> = ({
  errors,
  id,
  isDeleting,
  isLoading,
  name,
  onDelete,
  size,
  submitted,
}) => {
  let errorMsg: string | undefined;
  if (errors && errors.length) {
    errorMsg = errors[0].message;
  }

  const commonAttributes = { width: 4, height: 4 };
  let Icon = errorMsg ? (
    <AlertSolidIcon {...commonAttributes} data-testid="alert" />
  ) : (
    <TickSolidIcon {...commonAttributes} data-testid="tick" />
  );
  if (isLoading) {
    Icon = <Spinner {...commonAttributes} data-testid="spinner" />;
  }

  const prettySize = React.useMemo(() => {
    const UNITS = ['B', 'kB', 'MB'];
    let loopCounter = 0;
    let prettySize = size;

    while (prettySize > 1024) {
      prettySize = Math.round((prettySize / 1024) * 10) / 10;
      loopCounter++;
    }

    return `${prettySize}${UNITS[loopCounter]}`;
  }, [size]);

  const handleDelete = () => {
    onDelete(id);
  };

  const RemoveButton = () => {
    if (errorMsg) {
      return (
        <CloseButton
          disabled={isDeleting}
          onClick={handleDelete}
          title="remove"
          aria-label="remove"
          width={4}
          height={4}
        />
      );
    }

    if (submitted) {
      return null;
    }

    return (
      <button onClick={handleDelete} title="delete" disabled={isDeleting}>
        <BinIcon width={4} height={4} />
      </button>
    );
  };

  return (
    <Box
      as="li"
      listStyleType="none"
      borderRadius="md"
      border="1px"
      borderColor={errorMsg ? 'red.600' : 'brand.greyScale.400'}
      backgroundColor={errorMsg ? 'red.50' : 'white'}
      p={2}
      mt={2}
      opacity={isDeleting || submitted ? '.5' : '1'}
    >
      <Box data-testid="foobar" display="flex" alignItems="center">
        {Icon}
        <Box
          flex="1"
          display="flex"
          alignItems="center"
          overflow="hidden"
          mx={2}
          gap={2}
        >
          <Text
            fontSize="sm"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {name}
          </Text>
          {size && <Text fontSize="sm">{prettySize}</Text>}
        </Box>
        {!isLoading && <RemoveButton />}
      </Box>
      {errorMsg ? (
        <Text fontSize="sm" color="red.600">
          {errorMsg}
        </Text>
      ) : null}
    </Box>
  );
};

export const FileList: React.FC<FileListProps> = ({
  files,
  handleDeleteFile,
}) => {
  return (
    <div>
      <Heading as="h4" size="sm" mt={5}>
        Files attached
      </Heading>
      <Box as="ul" data-testid="file-list">
        {files.map(
          ({
            file: { name, size },
            errors,
            uuid,
            isDeleting,
            isLoading,
            submitted,
          }) => (
            <FileListItem
              key={uuid}
              name={name}
              size={size}
              errors={errors}
              id={uuid}
              onDelete={handleDeleteFile}
              isDeleting={isDeleting}
              isLoading={isLoading}
              submitted={submitted}
            />
          ),
        )}
      </Box>
    </div>
  );
};
