import { Text } from '@newday/core';
import { Hero, ResponsivePageLayout } from '../../components';
import { ApplicationReference } from '../../features';

export const DocUploadUnsuccessPage: React.FC = () => {

  return (
    <ResponsivePageLayout>
      <ApplicationReference />
      <Hero title="Hmm, that didn’t work" fullWidth>
        <Text fontWeight="bold" color="black">
          Something went wrong when you tried to upload your document(s).
        </Text>
        <Text pt={2} color="black">
          We&apos;ve emailed you a link to come back and complete your
          application.
        </Text>
      </Hero>
    </ResponsivePageLayout>
  );
};
