/* eslint-disable @typescript-eslint/ban-ts-comment */
import { whiten } from '@chakra-ui/theme-tools';
import {
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useTheme,
} from '@newday/core';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';

import type { PlumSupportedBrand } from '@newday/plum-types';
import {
  PAGE_TITLE_BY_PATH,
  useApplicationId,
  useBrand,
  useOptimizelyToggles,
} from '../../app';
import { useHashedAppIdQuery } from '../../shared/queries';
import { initOptimizely } from '../../utils';
import { setupGtm } from '../../utils/gtm-setup';
import {
  DataLayerEvents,
  Events,
  gtmTrackEvent,
} from '../../utils/gtm-track-event';

interface ManageCookiesBannerProps {
  manageCookiesIsOpen: boolean;
  manageCookiesOnClose: () => void;
  handleAcceptAll: () => void;
  brand: PlumSupportedBrand;
  setPageViewTracking: (state: boolean) => void;
}

enum CookieNames {
  MARKETING = 'marketingCookieConsent',
  ANALYTICS = 'analyticsCookieConsent',
  DEFAULT = 'cookieConsent',
}

const ManageCookiesBanner: React.FC<ManageCookiesBannerProps> = ({
  manageCookiesIsOpen,
  manageCookiesOnClose,
  handleAcceptAll,
  brand,
  setPageViewTracking,
}) => {
  const [enableMarketing, setEnableMarketing] = useState(false);
  const [enableAnalytics, setEnableAnalytics] = useState(false);

  const theme = useTheme();
  const bgColor = whiten('brand.quaternary', 80)(theme);

  const { setOptimizelyTogglesOnCookieAccept } = useOptimizelyToggles();

  const handleManageCookiesConfirmSelection = () => {
    if (enableMarketing) {
      Cookies.set(CookieNames.MARKETING, '1');
      gtmTrackEvent({
        event: Events.COOKIE_CONSENT_UPDATE,
        [DataLayerEvents.MARKETING_COOKIE_CONSENT]: 'true',
        form_name: '',
      });
    }

    if (enableAnalytics) {
      initOptimizely();
      window?.mouseflow?.start();
      setupGtm(brand);
      Cookies.set(CookieNames.ANALYTICS, '1');
      gtmTrackEvent({
        event: Events.COOKIE_CONSENT_UPDATE,
        [DataLayerEvents.ANALYTICS_COOKIE_CONSENT]: 'true',
        form_name: '',
      });
      setPageViewTracking(true);
      setOptimizelyTogglesOnCookieAccept(true);
    }

    Cookies.set(CookieNames.DEFAULT, '1');
    manageCookiesOnClose();
  };

  const handleAnalyticsSelection = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEnableAnalytics(e.target.checked);
  const handleMarketingSelection = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEnableMarketing(e.target.checked);

  return (
    <Modal
      size="3xl"
      isOpen={manageCookiesIsOpen}
      onClose={manageCookiesOnClose}
    >
      <ModalOverlay />
      <ModalContent
        borderRadius={{
          base: 'none',
          md: 'md',
        }}
        width={{
          md: '3xl',
        }}
        position={{
          base: 'absolute',
          md: 'static',
        }}
        mt={{
          base: 0,
        }}
      >
        <ModalHeader pt={5} px={{ base: 7, md: 10 }}>
          <Heading as="h4">Manage cookie settings</Heading>
        </ModalHeader>
        <ModalBody
          pt={{ md: 3 }}
          pb={{ md: 5 }}
          px={{ base: 7, md: 10 }}
          display={{
            md: 'flex',
          }}
          gap={{
            md: 2,
          }}
        >
          <Text fontWeight="semibold" mb={2}>
            Cookies are useful because they allow a website to recognise your
            visit and collect information about how you use that website.
          </Text>
          <Button
            onClick={handleAcceptAll}
            alignSelf="center"
            height="fit-content"
            borderRadius="md"
            width={{ base: 'full', md: 'unset' }}
            mb={5}
          >
            Accept all cookies
          </Button>
        </ModalBody>
        <ModalBody
          py={{ md: 5 }}
          px={{ base: 7, md: 10 }}
          backgroundColor="brand.greyScale.200"
        >
          <Heading as="h5" size="md" fontWeight="bold" mb={2} mt={5}>
            Strictly necessary cookies
          </Heading>
          <Text fontWeight="semibold" fontSize="sm" mb={5}>
            Strictly necessary cookies ensure that our website is able to
            function properly, and include cookies that help you log in, that
            provide core services and features, that help keep our website
            secure and that help us detect fraud or crime.
          </Text>
        </ModalBody>
        <ModalBody
          pt={{ md: 5 }}
          pb={{ base: 2, md: 0 }}
          px={{ base: 7, md: 10 }}
          backgroundColor={bgColor}
        >
          <Heading as="h5" size="md" fontWeight="bold" mb={2} mt={5}>
            Optional cookies
          </Heading>
          <Text fontWeight="semibold" fontSize="sm" mb={5}>
            We&apos;d also like to use cookies to provide optional features,
            improve our website and support our marketing. Where relevant, our
            trusted partners will help us make use of these optional cookies.
          </Text>
          <Divider border="2px" borderColor="brand.quaternary" />
        </ModalBody>
        <ModalBody
          py={{ md: 5 }}
          pb={{ base: 2, md: 0 }}
          px={{ base: 7, md: 10 }}
          backgroundColor={bgColor}
        >
          <Checkbox
            spacing="0"
            isChecked={enableAnalytics}
            onChange={handleAnalyticsSelection}
            display="flex"
            flexDirection="row-reverse"
            justifyContent="space-between"
            mb={2}
          >
            <Heading as="h5" fontWeight="semibold" size="md">
              Analytics cookies
            </Heading>
          </Checkbox>
          <Text fontSize="sm" mb={5}>
            We use digital tools to study how you interact with our website and
            to improve it accordingly – this is known as website analytics.
            These cookies help us understand how many visitors we have to the
            website, how many times they visit and how many times they viewed
            specific webpages within our site. Although these cookies allow us
            to gather specific information about the pages that you visit and
            whether you visited this website multiple times, we cannot use them
            to acquire details such as your name or address.
          </Text>
          <Divider border="2px" borderColor="brand.quaternary" />
        </ModalBody>
        <ModalBody
          py={{ md: 5 }}
          pb={{ base: 2, md: 0 }}
          px={{ base: 7, md: 10 }}
          backgroundColor={bgColor}
        >
          <Checkbox
            spacing={0}
            isChecked={enableMarketing}
            onChange={handleMarketingSelection}
            display="flex"
            flexDirection="row-reverse"
            justifyContent="space-between"
            mb={2}
          >
            <Heading as="h5" fontWeight="semibold" size="md">
              Marketing cookies
            </Heading>
          </Checkbox>
          <Text fontSize="sm" mb={5}>
            We and our partners use these cookies to understand what you&apos;re
            interested in on our website and on social media. This is so we or
            our partners can personalise our marketing to you.
          </Text>
        </ModalBody>
        <ButtonGroup
          justifyContent="center"
          flexFlow={{ base: 'column', md: 'row' }}
          spacing={{
            base: 0,
            md: 2,
          }}
          borderRadius={{
            base: 'none',
            md: 'md',
          }}
          pt={{ base: 3, md: 5 }}
          pb={{ base: 5 }}
          px={{ base: 7, md: 10 }}
          backgroundColor={bgColor}
        >
          <Button
            onClick={handleManageCookiesConfirmSelection}
            variant="tertiary"
            borderRadius="md"
            backgroundColor={bgColor}
            mb={{ base: 3 }}
            width={{
              md: 'full',
            }}
          >
            Confirm current selection
          </Button>
          <Button
            onClick={handleAcceptAll}
            borderRadius="md"
            width={{
              md: 'full',
            }}
            height={{
              md: 12,
            }}
          >
            Accept all cookies
          </Button>
        </ButtonGroup>
      </ModalContent>
    </Modal>
  );
};

export const CookieConsentBanner: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: manageCookiesIsOpen,
    onOpen: manageCookiesOnOpen,
    onClose: manageCookiesOnClose,
  } = useDisclosure();
  const { brand } = useBrand();
  const { applicationId } = useApplicationId();
  const { data, isSuccess } = useHashedAppIdQuery(applicationId);
  const [pageViewTracking, setPageViewTracking] = useState(false);
  const { setOptimizelyTogglesOnCookieAccept } = useOptimizelyToggles();

  useEffect(() => {
    if (pageViewTracking && isSuccess) {
      gtmTrackEvent({
        event: Events.PAGE_VIEW,
        virtualPageURL: location.pathname,
        virtualPageTitle:
          PAGE_TITLE_BY_PATH[location.pathname] || document.title,
        page: {
          brand,
        },
        application: {
          applicationId: data?.hashedApplicationId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageViewTracking, isSuccess]);

  useEffect(() => {
    const cookieConsent = Cookies.get(CookieNames.DEFAULT);

    if (cookieConsent) {
      initOptimizely();
      window?.mouseflow?.start();
      return;
    }
    onOpen();
  }, [onOpen]);

  const handleManageCookiesOnOpen = () => {
    onClose();
    manageCookiesOnOpen();
  };

  const handleManageCookiesOnClose = () => {
    manageCookiesOnClose();
    onClose();
  };

  const handleClickOnAcceptAllCookies = () => {
    onClose();
    manageCookiesOnClose();
    initOptimizely();
    setupGtm(brand);
    window?.mouseflow?.start();
    Cookies.set(CookieNames.MARKETING, '1');
    Cookies.set(CookieNames.ANALYTICS, '1');
    Cookies.set(CookieNames.DEFAULT, '1');
    gtmTrackEvent({
      event: Events.COOKIE_CONSENT_UPDATE,
      [DataLayerEvents.ANALYTICS_COOKIE_CONSENT]: 'true',
      [DataLayerEvents.MARKETING_COOKIE_CONSENT]: 'true',
      form_name: '',
    });

    setPageViewTracking(true);
    setOptimizelyTogglesOnCookieAccept(true);
  };

  return (
    <>
      <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          py={{ base: '1.75rem', md: '2.5rem' }}
          px={{ base: '1.75rem', md: '2.5rem' }}
          borderRadius={{
            base: 'none',
            md: 'md',
          }}
          width={{
            md: '3xl',
          }}
          position={{
            base: 'absolute',
            md: 'static',
          }}
          bottom={{
            base: 0,
          }}
          mb={{
            base: 0,
          }}
        >
          <ModalHeader p={0} mb={5}>
            <Heading as="h3">We respect your privacy</Heading>
          </ModalHeader>
          <ModalBody p={0} mb={5}>
            <Text fontWeight="semibold">
              Cookies are small pieces of data that websites store on your
              browser when you visit them. We use necessary cookies to ensure
              our website and services are able to function properly.
            </Text>
            <br />
            <Text>
              Analytics and marketing cookies are optional, they help us to
              improve your experience and support our marketing. Set preference
              by managing the cookies settings or accept both by selecting{' '}
              <strong>&apos;Accept all cookies&apos;</strong> below.
            </Text>
          </ModalBody>
          <ButtonGroup
            flexFlow={{ base: 'column-reverse', md: 'row' }}
            m={0}
            spacing={{
              base: 0,
              md: 2,
            }}
            justifyContent="center"
          >
            <Button
              onClick={handleClickOnAcceptAllCookies}
              borderRadius="md"
              width={{
                md: 'full',
              }}
              height={{
                md: 12,
              }}
            >
              Accept all cookies
            </Button>
            <Button
              mb={{ base: 3, md: 0 }}
              ml={{ base: 0 }}
              onClick={handleManageCookiesOnOpen}
              variant="tertiary"
              borderRadius="md"
              width={{
                md: 'full',
              }}
            >
              Manage cookie settings
            </Button>
          </ButtonGroup>
        </ModalContent>
      </Modal>
      <ManageCookiesBanner
        manageCookiesIsOpen={manageCookiesIsOpen}
        manageCookiesOnClose={handleManageCookiesOnClose}
        handleAcceptAll={handleClickOnAcceptAllCookies}
        brand={brand}
        setPageViewTracking={setPageViewTracking}
      />
    </>
  );
};
