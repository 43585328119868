import { ComponentStyleConfig } from '@chakra-ui/react';

const commonDividerStyles = {
  mt: '1.25rem',
  borderTopWidth: '2px',
  borderTopColor: 'brand.primary',
  opacity: 1,
};

const Stepper: ComponentStyleConfig = {
  baseStyle: {
    completed: {
      divider: {
        ...commonDividerStyles,
      },
    },
    active: {
      divider: {
        ...commonDividerStyles,
      },
    },
    inactive: {
      divider: {
        ...commonDividerStyles,
      },
    },
  },
  variants: {
    secondary: {
      completed: {
        divider: {
          ...commonDividerStyles,
        },
      },
      active: {
        divider: {
          ...commonDividerStyles,
          borderTopColor: 'brand.greyScale.800',
        },
      },
      inactive: {
        divider: {
          ...commonDividerStyles,
          borderTopColor: 'brand.greyScale.800',
        },
      },
    },
    tertiary: {
      completed: {
        divider: {
          ...commonDividerStyles,
          borderTopColor: 'brand.primary.500',
        },
      },
      active: {
        divider: {
          ...commonDividerStyles,
          borderTopColor: 'brand.primary.500',
        },
      },
      inactive: {
        divider: {
          ...commonDividerStyles,
          borderTopColor: 'brand.primary.500',
        },
      },
    },
  },
  defaultProps: {
    variant: 'primary',
  },
};

export default Stepper;
