import { GetApplication } from './application.types';

export type ApplicationEligibilityStateAndSubstate = {
  state: 'quoteAccept' | 'quoteDecline';
  subState:
    | ''
    | 'refer'
    | 'referUpsell'
    | 'referDownsell'
    | 'upsell'
    | 'downsell'
    | 'headline'
    | 'complexRefer'
    | 'dupeApplication'
    | 'exisitingCust'
    | 'creditLock';
};

export type ApplicationEligibilityResponse =
  ApplicationEligibilityStateAndSubstate & {
    customerRef?: string;
    modifiedAt?: GetApplication['modifiedAt'];
  };

export enum DependantsCount {
  none = '0',
  one = '1',
  two = '2',
  threeOrMore = '3',
}

export enum EmploymentStatus {
  fullTime = 'fullTime',
  partTime = 'partTime',
  selfEmployed = 'selfEmployed',
  contractor = 'contractor',
  temporary = 'temporary',
  retired = 'retired',
  homemaker = 'homemaker',
  disability = 'disability',
  allowance = 'allowance',
  independentMeans = 'independentMeans',
  student = 'student',
  unemployed = 'unemployed',
  other = 'other',
}

export enum FinancialSituation {
  improve = 'improve',
  staySame = 'staySame',
  decline = 'decline',
}

export enum MaritalStatus {
  married = 'married',
  single = 'single',
  divorced = 'divorcedOrDissolvedCivilPartnership',
  widowed = 'widowed',
  cohabiting = 'cohabiting',
  toBeMarried = 'toBeMarried',
  separated = 'separated',
  civilPartnership = 'civilPartnership',
  other = 'other',
}

export enum ResidentialStatus {
  ownerOccupier = 'ownerOccupier',
  jointOwner = 'jointOwner',
  tenantFurnished = 'tenantFurnished',
  tenantUnfurnished = 'tenantUnfurnished',
  councilHousing = 'councilHousing',
  studentHousing = 'studentHousing',
  livingWithParents = 'livingWithParents',
  otherTenant = 'otherTenant',
  other = 'other',
}

