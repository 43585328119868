import React from 'react';
import { Link } from '@newday/core';
import { CreditBuilderIcon, PageIcon } from '@newday/icons';
import { AdvicePanel } from '../advice-panel/advice-panel';
import { FaqPanel } from './faq-panel';

export const BoostCreditScoreHelp: React.FC = () => (
  <>
    <AdvicePanel
      icon={<PageIcon />}
      title="Learn how to look your best for lenders"
      description={
        <>
          Get expert tips in the{' '}
          <Link
            fontWeight="semibold"
            href={process.env.PLUM_LINK_AQUA_CREDIT_HUB}
            color="black"
            isExternal
          >
            Aqua credit hub
          </Link>
          . Come back regularly to see the latest articles.
        </>
      }
    />
    <AdvicePanel
      icon={<CreditBuilderIcon />}
      title="Meet your credit-building buddy"
      description={
        <>
          Track your credit score and learn what’s driving it with{' '}
          <Link
            fontWeight="semibold"
            href={process.env.PLUM_LINK_AQUA_CREDIT_COACH}
            color="black"
            isExternal
          >
            Aqua Coach
          </Link>
          . Find it under ‘more’ in your Aqua app.
        </>
      }
    />
    <FaqPanel />
  </>
);
