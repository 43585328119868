import { ReactElement } from 'react';
import {
  Accordion as ChakraAccordion,
  AccordionProps,
  AccordionItem as ChakraAccordionItem,
  AccordionItemProps,
  AccordionButton as ChakraAccordionButton,
  AccordionButtonProps,
  AccordionPanel as ChakraAccordionPanel,
  AccordionPanelProps,
  useMultiStyleConfig,
  useStyleConfig,
  SystemStyleObject,
} from '@chakra-ui/react';
import { CircledDownArrowIcon } from '@newday/icons';

type AccordionVariants = 'default' | 'outline' | 'aqua';

export interface CustomAccordionProps extends AccordionProps {
  variant?: AccordionVariants;
}

export const Accordion = (props: CustomAccordionProps): ReactElement => {
  const { variant } = props;
  const styles = useMultiStyleConfig('Accordion', { variant });
  return (
    <ChakraAccordion __css={styles} allowToggle {...props}>
      {props.children}
    </ChakraAccordion>
  );
};

export const AccordionItem = (
  props: AccordionItemProps & { variant?: 'alert' }
): ReactElement => {
  const { variant } = props;
  const styles = useMultiStyleConfig('AccordionItem', { variant });

  return (
    <ChakraAccordionItem sx={styles} {...props}>
      {props.children}
    </ChakraAccordionItem>
  );
};

export const AccordionButton = (
  props: AccordionButtonProps & { variant?: 'default-alert' | 'outline-alert' }
): ReactElement => {
  const { variant } = props;
  const styles = useStyleConfig('AccordionButton', { variant });
  return (
    <ChakraAccordionButton {...props} sx={styles}>
      {props.children}
    </ChakraAccordionButton>
  );
};

export const AccordionIcon = ({
  variant = undefined,
  expanded = false,
  style = {},
}: {
  variant?: 'alert';
  expanded?: boolean;
  style?: Record<string, string>;
}): ReactElement => {
  const styles = useMultiStyleConfig('AccordionIcon', { variant });

  const previousIconTransform = styles['icon']?.transform;

  const iconStyles: SystemStyleObject = {
    ...styles['icon'],
    transform: !expanded ? previousIconTransform : undefined,
  };

  return (
    <CircledDownArrowIcon
      ml={6}
      size="sm"
      sx={{ ...iconStyles, ...style }}
    />
  );
};

export const AccordionPanel = (props: AccordionPanelProps): ReactElement => {
  return (
    <ChakraAccordionPanel {...props}>{props.children}</ChakraAccordionPanel>
  );
};
