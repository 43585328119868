import { WarningIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Heading,
  HStack,
  Link,
  Skeleton,
  Text,
  ValidationMessage,
  VStack,
} from '@newday/core';
import { DecisionAndApplyStatuses, FeRoutes } from '@newday/plum-types';
import { DocumentTypes } from '@newday/acquisitions-api-client';
import React, {
  ChangeEvent,
  useCallback,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import type { HtmlDocs } from '.';
import { AccordionContent, useApplyAndSign, useHtmlDocuments } from '.';
import { useApplicationId, useBrand } from '../../app';
import { ContentWrapper, Loading } from '../../components';
import { useRedirect } from '../../shared/queries';
import {
  Events,
  FormActions,
  FormNames,
  gtmTrackEvent,
} from '../../utils/gtm-track-event';

const Documents = {
  [DocumentTypes.loanSummary]: {
    title: 'Pre-contract credit information',
    subText:
      'You can use this document to compare our personal loans against others. It includes details of the type of credit, term, repayments, rates of interest and other fees and charges.',
  },
  [DocumentTypes.loanExplanation]: {
    title: '<brand> loans explained',
    subText:
      'It’s very important for you to be sure this personal loan is right for you before you sign the agreement. This document will help you to make that decision. In it, you’ll find the product’s key features explained, along with important details such as what will happen if you fail to make payments.',
  },
  [DocumentTypes.loanAgreement]: {
    title: 'Your <brand> loan agreement',
    subText: `By ticking the e-signature box below, you: \n(i) Acknowledge that you’ve been provided with, and have had the opportunity to consider, the pre-contractual information about the <brand> personal loan set out above\n(ii) Confirm that you’ve read and agreed to the terms of the <brand> personal loan agreement and that you wish to enter into the agreement with us`,
  },
};

const filesReadReducer = (state, { document }: { document: string }) => ({
  ...state,
  [document]: true,
});

const fileErrorReducer = (
  state,
  { document, error }: { document: string; error: string }
) => {
  return { ...state, [document]: error };
};

export const ApplyForYourLoan = () => {
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const { applicationId } = useApplicationId();
  const { uppercaseBrandName } = useBrand();

  const { data: htmlDocuments, isLoading } = useHtmlDocuments(
    applicationId
  ) as unknown as HtmlDocs;

  const [{ signed, signatureError }, setSignature] = useState({
    signed: false,
    signatureError: '',
  });

  const [filesReadObj, dispatch] = useReducer(filesReadReducer, {
    [DocumentTypes.loanSummary]: false,
    [DocumentTypes.loanExplanation]: false,
  });

  const [accordionFileErrors, dispatchFileError] = useReducer(
    fileErrorReducer,
    {
      [DocumentTypes.loanSummary]: '',
      [DocumentTypes.loanExplanation]: '',
    }
  );
  const requiredFields = useRef<Array<HTMLDivElement | null>>([]);

  const {
    mutate: applyAndSign,
    isSuccess: isApplyAndSignRequestSuccessful,
    data: decisionResponse,
    isLoading: isApplyAndSignRequestLoading,
  } = useApplyAndSign(applicationId);

  const { data: redirectData, isSuccess: isRedirectSuccess } = useRedirect(
    applicationId,
    shouldRedirect,
    'apply-sign-redirect'
  );

  const navigate = useNavigate();

  React.useEffect(() => {
    if (isRedirectSuccess) {
      navigate(redirectData?.data?.redirect);
    }
  }, [isRedirectSuccess, redirectData, navigate]);

  React.useEffect(() => {
    if (isApplyAndSignRequestSuccessful && decisionResponse?.decision) {
      switch (decisionResponse?.decision) {
        case DecisionAndApplyStatuses.Accepted: {
          navigate(FeRoutes.bankDetails);
          break;
        }

        case DecisionAndApplyStatuses.Declined: {
          navigate(FeRoutes.hardCheckIneligible);
          break;
        }

        case DecisionAndApplyStatuses.Redirect: {
          setShouldRedirect(true);
          break;
        }
      }
    }
  }, [isApplyAndSignRequestSuccessful, decisionResponse?.decision, navigate]);

  const handleSubmit = () => {
    const formName = window.location.pathname.includes(
      FeRoutes.quoteCounterOffer
    )
      ? FormNames.APPLICATION_COUNTER
      : FormNames.APPLICATION;
    applyAndSign(undefined, {
      onSuccess: () => {
        gtmTrackEvent({
          event: Events.FORM_SIGNATURE,
          form_name: formName,
          form_action: FormActions.ESIGN_FORM,
          link_text: 'your eSignature',
        });
        gtmTrackEvent({
          event: Events.FORM_CTA,
          form_name: formName,
          form_action: FormActions.SUBMIT_FORM,
          link_text: 'apply for loan',
        });
      },
    });
  };

  const onSign = ({ target }: ChangeEvent<HTMLInputElement>) => {
    window.location.pathname.includes(FeRoutes.quoteCounterOffer)
      ? FormNames.APPLICATION_COUNTER
      : FormNames.APPLICATION;
    setSignature({
      signatureError: '',
      signed: target.checked,
    });
  };

  const hasSignedAndReadFiles = useCallback(() => {
    let hasRead = true;
    let hasSigned = true;
    Object.keys(filesReadObj).forEach((doc) => {
      if (filesReadObj[doc] === false) {
        dispatchFileError({
          document: doc,
          error: "You'll need to read this before you apply",
        });
        hasRead = false;
      }
    });

    if (!signed) {
      setSignature((prevState) => ({
        ...prevState,
        signatureError: 'Please tick the box to continue',
      }));
      hasSigned = false;
    }

    if (!hasRead && requiredFields.current[0]) {
      requiredFields.current[0].scrollIntoView({
        behavior: 'smooth',
      });
    }

    if (hasRead && !hasSigned && requiredFields.current[1]) {
      requiredFields.current[1].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }

    return hasRead && hasSigned;
  }, [dispatchFileError, setSignature, signed, filesReadObj]);

  const onSubmitForm = () => {
    if (hasSignedAndReadFiles()) {
      handleSubmit();
    }
  };

  const LoanSignature = useMemo(() => {
    return (
      <Box p={4} mt={5} bg="brand.greyScale.200">
        <div ref={(el) => (requiredFields.current[1] = el)}>
          <Text mb={5} fontSize="sm" lineHeight="shorter" fontWeight="semibold">
            This {uppercaseBrandName} personal loan agreement is regulated by
            the Consumer Credit Act 1974. Sign it only if you want to be legally
            bound by its terms. Please tick the box to add your e-signature to
            this credit agreement. Then click the ‘apply for loan’ button.
          </Text>
          <Checkbox
            w="full"
            variant="primary"
            onChange={onSign}
            bg={(signatureError && 'red.600') || ''}
            aria-label="Add e-signature"
          >
            <Text aria-hidden="true">Add e-signature</Text>
          </Checkbox>
          {signatureError && (
            <ValidationMessage
              mt={3}
              alignItems="flex-start"
              message={signatureError}
            />
          )}
        </div>
      </Box>
    );
  }, [signatureError, uppercaseBrandName]);

  const HtmlDocs = useMemo(() => {
    const AccordionItems = Object.entries(Documents).map(
      ([documentType, { title: titleWithPlaceholderBrand, subText }], i) => {
        const hasError = accordionFileErrors[documentType];
        const isAutoExpanded = documentType === DocumentTypes.loanAgreement;
        const title = titleWithPlaceholderBrand.replace(
          /<brand>/i,
          uppercaseBrandName
        );
        return (
          <AccordionItem
            key={i}
            isDisabled={isAutoExpanded}
            variant={hasError && 'alert'}
          >
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  justifyContent="space-between"
                  variant={hasError && 'default-alert'}
                  _disabled={
                    (isAutoExpanded && {
                      opacity: 1,
                      cursor: 'default !important',
                      background: 'white !important',
                    }) ||
                    {}
                  }
                  onClick={() => {
                    dispatch({ document: documentType });
                    hasError &&
                      dispatchFileError({ document: documentType, error: '' });
                  }}
                >
                  {!hasError && (
                    <>
                      {title}
                      {!isAutoExpanded && (
                        <AccordionIcon expanded={isExpanded} />
                      )}
                    </>
                  )}
                  {hasError && !isExpanded && (
                    <Box
                      flex="1"
                      textAlign="left"
                      justifyContent="space-between"
                      alignItems="center"
                      display="flex"
                    >
                      <div>
                        {title}
                        <HStack mt={2}>
                          <WarningIcon w={5} h={5} />
                          <Text fontSize="sm">{hasError}</Text>
                        </HStack>
                      </div>
                      <AccordionIcon
                        expanded={isExpanded}
                        variant="alert"
                        style={{ marginLeft: '0px !important' }}
                      />
                    </Box>
                  )}
                </AccordionButton>
                <AccordionPanel>
                  <AccordionContent
                    applicationId={applicationId}
                    documentType={documentType as keyof typeof DocumentTypes}
                    subText={subText.replace(/<brand>/g, uppercaseBrandName)}
                  >
                    {isLoading && <Skeleton height="300px" />}
                    {!isLoading && htmlDocuments.length > 0 && (
                      <iframe
                        data-mf-replace="*****"
                        data-testid="loan-docs-iframe"
                        title={title}
                        srcDoc={htmlDocuments[i].data}
                        style={{
                          width: '100%',
                          height: '300px',
                          overflow: 'scroll',
                        }}
                        tabIndex={0}
                      />
                    )}
                  </AccordionContent>
                  {isAutoExpanded && LoanSignature}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        );
      }
    );

    return (
      <Accordion
        variant="outline"
        defaultIndex={[2]}
        allowMultiple={true}
        mb={5}
      >
        {AccordionItems}
      </Accordion>
    );
  }, [
    htmlDocuments,
    accordionFileErrors,
    LoanSignature,
    isLoading,
    applicationId,
    uppercaseBrandName,
  ]);

  return (
    <Box variant="brand-quaternary-800" py={8} flexDirection="column">
      <ContentWrapper>
        <Heading fontSize="2xl" fontWeight="bold">
          How to apply for your loan
        </Heading>
        <Text
          fontSize="md"
          lineHeight="base"
          fontWeight="600"
          mt="0.5rem"
          mb="1.25rem"
        >
          <strong>
            First, please carefully read the important information below about
            the loan you’ve been offered.{' '}
          </strong>
          You can also download or print copies to keep. If you’re comfortable
          with the loan terms, sign your loan agreement by adding your
          e-signature then apply below.
        </Text>
        <div ref={(el) => (requiredFields.current[0] = el)}>{HtmlDocs}</div>
        <Box backgroundColor="white" padding={4} mb={8}>
          <Heading mb={2.5}>Digital communications</Heading>
          <Text fontSize="sm" fontWeight="semibold">
            By clicking <strong>‘apply for loan’</strong> below you consent to
            receiving statements, notices and other important communications by
            email and through your online account. You’ll receive an email
            whenever a communication is available, so let us know if your email
            address changes.
          </Text>
        </Box>
        <Box
          backgroundColor="white"
          padding={4}
          mb={8}
          maxH={48}
          overflowY="scroll"
          tabIndex={0}
        >
          <Heading mb={2.5}>Credit and fraud checks</Heading>
          <Text fontSize="sm" fontWeight="semibold">
            NewDay may ask you to provide documentation to prove your identity.
            NewDay will check its own records about you and those at credit
            reference agencies (CRAs) and fraud prevention agencies (FPAs). When
            CRAs carry out a full search, they place a search footprint on your
            credit file that may be seen by other lenders and affect your
            ability to get credit elsewhere for a short time. CRAs supply to
            NewDay both public and shared credit and fraud information.
          </Text>
          <br />
          <Text fontSize="sm" fontWeight="semibold">
            If you tell NewDay that you have a spouse or financial associate,
            NewDay and CRAs will link your records together, so make sure that
            you have their agreement to disclose information about them. These
            links will remain on your and their files until one of you
            successfully files for a disassociation with the CRAs to break that
            link.
          </Text>
          <br />
          <Text fontSize="sm" fontWeight="semibold">
            Information on this application and details of how you manage your
            account will be sent to CRAs, will be recorded by them and may be
            used by other lenders to make lending decisions. If you don’t pay
            what you owe on time, CRAs will record the outstanding debt and this
            information may be given to and used by other organisations. Records
            remain on file for six years after accounts are closed, settled or
            defaulted. NewDay and its successors in title to your loan account
            may also make periodic searches at CRAs and FPAs to manage your
            account.
          </Text>
          <br />
          <Text fontSize="sm" fontWeight="semibold">
            NewDay uses credit scoring and other automated decision-making when
            considering your application. If you give NewDay false or inaccurate
            information and NewDay suspects or identifies fraud, NewDay will
            record this and may pass information to FPAs and other organisations
            involved in fraud and crime prevention. NewDay and other
            organisations may access and use from other countries the
            information recorded by FPAs. If fraud is detected, you could be
            refused certain services, finance or employment.
          </Text>
          <br />
          <Text fontSize="sm" fontWeight="semibold">
            If you don’t pay what you owe, NewDay (or its successors in title)
            will trace your whereabouts and recover the debts.
          </Text>
          <br />
          <Text fontSize="sm" fontWeight="semibold">
            There is more information about how your data may be used by NewDay,
            the CPAs and FPAs and your data protection rights in the NewDay
            Privacy Notice.
          </Text>
        </Box>
        <Text mb={8} fontWeight="semibold">
          By applying for a loan you confirm that you’re happy with the credit
          and fraud information above and for us to share data relevant to your
          loan with Antelope Loans and Antelope Loans Funding Limited, plus
          you’ve read their{' '}
          <Link
            href="https://antelopeloastg.wpengine.com/privacy-statement/"
            color="black"
            rel="noopener noreferrer"
            isExternal
          >
            privacy notice
          </Link>
          .
        </Text>
        <VStack w="full">
          <Button w="full" onClick={onSubmitForm} mb={2}>
            Apply for loan
          </Button>
          <Button
            w="full"
            onClick={() => navigate(FeRoutes.eligibility)}
            variant="tertiary"
          >
            Back
          </Button>
        </VStack>
        <Loading
          isLoading={isApplyAndSignRequestLoading}
          title="Please wait while we process your application..."
          secondsToLoad={20}
        />
      </ContentWrapper>
    </Box>
  );
};
