import React, { ReactNode } from 'react';
import { Stack, Text } from '@newday/core';
import { DocumentTypes } from '@newday/acquisitions-api-client';
import { DownloadPdfButton } from '../download-pdf';

type AccordionContentProps = {
  documentType: keyof typeof DocumentTypes;
  applicationId: string;
  subText: string;
  children: ReactNode;
};
const AccordionContent: React.FC<AccordionContentProps> = ({
  documentType,
  applicationId,
  subText,
  children,
}) => (
  <Stack spacing="1.25rem">
    <Text
      fontSize="sm"
      lineHeight="shorter"
      fontWeight="400"
      style={{ whiteSpace: 'pre-wrap' }}
    >
      {subText}
    </Text>
    <DownloadPdfButton
      applicationId={applicationId}
      documentType={documentType}
    />
    <Stack
      bg="white"
      borderWidth="1px"
      borderColor="brand.primary"
      borderRadius="5px"
      spacing="1.25rem"
      py=".5rem"
    >
      {children}
    </Stack>
  </Stack>
);

export { AccordionContent };
