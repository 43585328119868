import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory();
export const reactPlugin = new ReactPlugin();

const APPLICATIONINSIGHTS_CONNECTION_STRING = process.env.PLUM_APPLICATIONINSIGHTS_CONNECTION_STRING;

export const initializeAppInsights = () => {
  if (!APPLICATIONINSIGHTS_CONNECTION_STRING || !APPLICATIONINSIGHTS_CONNECTION_STRING?.startsWith('InstrumentationKey')) {
    console.warn('No valid Application Insights connection string found');
    return;
  }
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: APPLICATIONINSIGHTS_CONNECTION_STRING,
      extensions: [reactPlugin],
      enableAutoRouteTracking: true,
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });

  appInsights.loadAppInsights();
  appInsights.trackPageView();
};
