import React from 'react';
import { useApplicationId, useFeatureFlags, useLoanDetails } from '../../app';
import { LoanBreakdown as LoanBreakdownComponent } from '../../components';
import type { LoanBreakdownProps as LoanBreakdownComponentProps } from '../../components';
import { useAprAndRiskGroup } from '../../shared/queries';

interface LoanBreakdownProps
  extends Pick<LoanBreakdownComponentProps, 'verbose' | 'variant' | 'layout'> {
  isLoaded?: boolean;
}

const LoanBreakdown: React.FC<LoanBreakdownProps> = ({
  verbose = false,
  variant,
  layout,
}) => {
  const { DYNAMIC_PRICING } = useFeatureFlags();
  const {
    loanDetails: { amount, durationYears },
  } = useLoanDetails();

  const { applicationId } = useApplicationId();

  const { data: aprAndRiskGroup, isSuccess } =
    useAprAndRiskGroup(applicationId);

  const loanValue = aprAndRiskGroup?.loanValues?.find(
    ({ from, to }) => amount >= from && amount <= to,
  );
  const price = loanValue?.prices.find(
    ({ term }) => durationYears * 12 === term,
  );
  const dynamicApr =
    aprAndRiskGroup?.realRateInd === 1 && price?.apr && price?.apr >= 0
      ? price?.apr
      : aprAndRiskGroup?.apr;

  const apr = DYNAMIC_PRICING ? dynamicApr : aprAndRiskGroup?.apr;

  if (isSuccess && apr) {
    return (
      <LoanBreakdownComponent
        apr={apr}
        durationYears={durationYears}
        loanAmount={amount}
        variant={variant}
        verbose={verbose}
        layout={layout}
      />
    );
  }

  return (
    <LoanBreakdownComponent
      apr={10} // any apr just to calculate skeleton height
      durationYears={durationYears}
      isLoaded={false}
      loanAmount={amount}
      variant={variant}
      verbose={verbose}
      layout={layout}
    />
  );
};

export { LoanBreakdown };
