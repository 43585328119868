import React from 'react';

import { Text, Box, Divider, Flex, Heading } from '@newday/core';
import type { BoxProps } from '@newday/core';
import type {
  Sections,
  Section as SectionType,
  SubSection as SubSectionType,
} from '.';
import { SectionStyles } from '.';
import { FormQuestion } from '../form-question';
import { useConditionsMatched } from '../form-conditions';
import type { Condition } from '../form-conditions';

interface SectionContainerProps extends BoxProps {
  layout: SectionStyles;
  conditions?: Condition[];
  contentWrapper?: any;
}

const SectionContainer: React.FC<SectionContainerProps> = ({
  layout,
  conditions,
  contentWrapper: ContentWrapper,
  children,
  ...props
}) => {
  const bgColor =
    layout === SectionStyles.default ? 'white' : 'brand.quaternary';

  const conditionsMatched = useConditionsMatched(conditions);

  return conditionsMatched ? (
    <Box bg={bgColor} {...props}>
      {ContentWrapper ? (
        <ContentWrapper px={{ base: 0, md: 8, lg: 0 }}>
          {children}
        </ContentWrapper>
      ) : (
        children
      )}
    </Box>
  ) : null;
};

const Title: React.FC<{
  title?: string;
  description?: string;
  as: 'h2' | 'h3';
}> = ({ title, description, as = 'h2' }) =>
  title ? (
    <Text
      as={as}
      fontSize="lg"
      color="brand.primary"
      fontWeight="bold"
      mt={5}
      mb={description ? 2 : 3}
    >
      {title}
    </Text>
  ) : null;

const Description: React.FC<{ description?: string }> = ({ description }) =>
  description ? (
    <Text fontSize="md" mb={3}>
      {description}
    </Text>
  ) : null;

const FormSectionTitle: React.FC<{ sectionTitle: string }> = ({
  sectionTitle,
}) => (
  <Flex pt={6} pb={3} direction="column">
    <Heading size="xl" fontWeight="bold">
      {sectionTitle}
    </Heading>
    <Divider my={2} borderTopWidth="1px" borderTopColor="brand.greyScale.400" />
  </Flex>
);

const SubSection: React.FC<{
  subSection: SubSectionType;
}> = ({ subSection: { title, description, questions, sectionTitle } }) => (
  <>
    {sectionTitle && <FormSectionTitle sectionTitle={sectionTitle} />}
    <Title as="h2" title={title} description={description} />
    <Description description={description} />
    {questions.map((question) => (
      <FormQuestion key={question.id} question={question} />
    ))}
  </>
);

const Section: React.FC<{ section: SectionType }> = ({
  section: { title, description, items },
}) => {
  return (
    <>
      <Title as="h2" title={title} description={description} />
      <Description description={description} />
      {items.map((item, index) =>
        'questions' in item ? (
          <SectionContainer
            key={`sub-section-${index}`}
            conditions={item.conditions}
            layout={item.layout}
            px={4}
            mb={5}
          >
            <SubSection subSection={item} />
          </SectionContainer>
        ) : (
          <FormQuestion key={item.id} question={item} />
        )
      )}
    </>
  );
};

const FormSections: React.FC<{
  formSections: Sections;
  contentWrapper?: any;
}> = ({ formSections, contentWrapper }) => {
  return (
    <div data-testid="form-sections">
      {formSections.map((section, index) => (
        <SectionContainer
          key={`section-${index}`}
          conditions={section.conditions}
          layout={section.layout}
          px={{ base: 8, md: 0 }}
          py={3}
          contentWrapper={contentWrapper}
        >
          {'items' in section ? (
            <Section section={section} />
          ) : (
            <SubSection subSection={section} />
          )}
        </SectionContainer>
      ))}
    </div>
  );
};

export { FormSections };
