import React, { useEffect, useState } from 'react';
import { ContentWrapper, ResponsivePageLayout } from '../../components';
import { DocUploadHero } from './doc-upload-hero';
import { ProofOfIncomeDocuments} from '../../features';
import { useApplicationId} from '../../app';
import {
  Box,
  Heading,
  Link,
  ListIcon,
  ListItem,
  Skeleton,
} from '@newday/core';
import { IncomeIcon, LiMarker } from '@newday/icons';
import { useNavigate } from 'react-router-dom';
import { FeRoutes } from '@newday/plum-types';
import {
  useEmploymentStatus,
} from '../../shared/queries';
import { Events, gtmTrackEvent } from '../../utils/gtm-track-event';

export const LI = ({ children }) => (
  <ListItem fontSize="sm" display="flex" alignItems="baseline">
    <ListIcon as={LiMarker} width="7px" height="auto" />
    {children}
  </ListItem>
);

export const DocUploadPage = () => {
  const navigate = useNavigate();

  const { applicationId } = useApplicationId();

  const { data: employmentData, isLoading: isEmploymentLoading } =
    useEmploymentStatus(applicationId);

  const [isEmploymentStatusRetired, setIsEmploymentStatusRetired] =
    useState(false);

  useEffect(() => {
    setIsEmploymentStatusRetired(
      employmentData?.employmentStatus === 'retired'
    );
  }, [employmentData, isEmploymentStatusRetired]);

  /* istanbul ignore next */
  const handleSelection = (
    mode: 'skip_upload'
  ) => {
    gtmTrackEvent({
      event: Events.INCOME_VERIFICATION_SELECTION,
      verification_mode: mode,
    });

    navigate(FeRoutes.documentUploadSkipped);
  };

  const handleSkip = () => {
    handleSelection('skip_upload');
  };

  return (
    <ResponsivePageLayout>
      <DocUploadHero />
      <ContentWrapper py={8}>
        <Skeleton isLoaded={!isEmploymentLoading}>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            w="full"
          >
            <IncomeIcon />
            <Heading as="h2" color="brand.primary" my={2}>
              Proof of income
            </Heading>
            <ProofOfIncomeDocuments />
            <Link
              as="button"
              mt={8}
              color="brand.primary"
              textUnderlineOffset={1}
              textDecorationColor="brand.tertiary"
              onClick={handleSkip}
            >
              Skip and upload later
            </Link>
          </Box>
        </Skeleton>
      </ContentWrapper>
    </ResponsivePageLayout>
  );
};
