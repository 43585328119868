import { Proof } from '@newday/plum-types';
import React, { createContext, useContext, useMemo } from 'react';
import { FileError } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { useApplicationId } from './use-application-id';

export interface PartialFile extends Partial<File> {
  readonly name: string;
  readonly size: number;
}

export interface MetaFile {
  file: PartialFile;
  errors?: FileError[];
  uuid: string;
  id?: string;
  isLoading?: boolean;
  isDeleting?: boolean;
  type: string;
  batchNo?: string;
  submitted: boolean;
}

type FilesContextType = {
  files: MetaFile[];
  handleAddFiles: (files: MetaFile[]) => void;
  handleDeleteFile: (id: string) => void;
  handleDeleteFiles: () => void;
  fetchFilesIsLoading: boolean;
  handleSubmitFiles: () => void;
  isSubmitLoading: boolean;
  isSubmitSuccess: boolean;
  isSubmitError: boolean;
};

export const FilesContext = createContext<FilesContextType>(
  {} as FilesContextType,
);

const FilesContextProvider = ({
  children,
  useManageFile,
  useFetchFiles,
  useUploadSubmit,
}) => {
  const { uploadFile, deleteFile, deleteFiles } = useManageFile();
  const { applicationId } = useApplicationId();
  const { data: fetchedFiles, isFetching: fetchFilesIsLoading } =
    useFetchFiles(applicationId);
  const [files, setFiles] = React.useState<MetaFile[]>([]);

  const {
    mutate: submitFiles,
    isLoading: isSubmitLoading,
    isSuccess: isSubmitSuccess,
    isError: isSubmitError,
  } = useUploadSubmit(applicationId);

  const handleDeleteFile = async (localId: string) => {
    setFiles((prevFiles) =>
      prevFiles.map((file) => ({
        ...file,
        isDeleting: true,
      })),
    );

    try {
      const file = files.find(({ uuid }) => uuid === localId);
      const deletedFileId = file?.id;

      if (deletedFileId) {
        await deleteFile({ id: deletedFileId, applicationId });
      }

      setFiles((prevFiles) =>
        prevFiles
          .filter((file) => file.uuid !== localId)
          .map((file) => ({
            ...file,
            isDeleting: false,
          })),
      );
    } catch (e) {
      setFiles((prevFiles) =>
        prevFiles.map((file) =>
          file.uuid === localId
            ? {
                ...file,
                isDeleting: false,
                errors: [
                  {
                    code: '',
                    message: 'Unable to delete, please try again',
                  },
                ],
              }
            : { ...file, isDeleting: false },
        ),
      );
    }
  };

  const handleDeleteFiles = () => {
    deleteFiles(applicationId);

    setFiles((prevFiles) =>
      prevFiles.filter((file) => file.submitted === true),
    );
  };

  const handleAddFiles = async (newFiles: MetaFile[]) => {
    const batchNo = uuidv4();

    setFiles((prevFiles) => [
      ...prevFiles,
      ...newFiles.map((file) => ({ ...file, batchNo })),
    ]);

    const filesWithId = newFiles.map(async ({ file, ...rest }) => {
      if (rest.errors) {
        return {
          file,
          ...rest,
        };
      }

      try {
        const response = await uploadFile({
          file,
          applicationId,
          type: rest.type,
        });

        return {
          file,
          ...rest,
          id: response.data.id,
          isLoading: false,
        };
      } catch (e) {
        return {
          file,
          ...rest,
          isLoading: false,
          errors: [{ message: 'An error occurred' }],
        };
      }
    });

    const resolvedValues = await Promise.allSettled(filesWithId);

    setFiles((prevFiles) => {
      return [
        ...prevFiles.filter((file) => file.batchNo !== batchNo),
        ...resolvedValues.map(
          (result) => (result as PromiseFulfilledResult<MetaFile>).value,
        ),
      ];
    });
  };

  useMemo(() => {
    const decoratedFetchedFiles: MetaFile[] =
      fetchedFiles?.map(
        ({ fileName, size, type: remoteType, id, submitted }: Proof) => {
          return {
            file: {
              name: fileName,
              size,
            },
            type: remoteType,
            id,
            uuid: uuidv4(),
            submitted,
          };
        },
      ) ?? [];

    setFiles(decoratedFetchedFiles);
  }, [fetchedFiles]);

  const handleSubmitFiles = () => {
    submitFiles();
  };

  return (
    <FilesContext.Provider
      value={{
        files,
        handleAddFiles,
        handleDeleteFile,
        handleDeleteFiles,
        fetchFilesIsLoading,
        handleSubmitFiles,
        isSubmitLoading,
        isSubmitSuccess,
        isSubmitError,
      }}
    >
      {children}
    </FilesContext.Provider>
  );
};

export const useFiles = () => {
  return useContext(FilesContext);
};

export default FilesContextProvider;
