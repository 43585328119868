import { useMemo } from 'react';

const debounce = (delay: number) => {
  let timer: NodeJS.Timeout;
  return (fn: () => void) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn();
    }, delay);
  };
};

export const useDebounce = (delay: number) => {
  return useMemo(() => debounce(delay), [delay]);
};
