export enum RiskGroup {
  high = 'High',
  low = 'Low',
}

export type AprAndRiskGroup = {
  apr: number;
  riskGroup: RiskGroup;
  minLoanValue: number;
  maxLoanValue: number;
  minLoanTerm: number;
  maxLoanTerm: number;
  realRateInd: number;
  loanValues: {
    from: number;
    to: number;
    prices: { term: number; apr: number }[];
  }[];
};

export interface LoanDetails {
  loanTerm: number;
  loanAmount: number;
}
