import { whiten } from '@chakra-ui/theme-tools';
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure,
  useTheme,
} from '@newday/core';
import { Form, FormSections } from '@newday/plum-form';
import { FrontEligibilityQuestions as EligibilityQuestions } from '@newday/plum-types';
import { formatISO } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { eligibilityQuestions as eligibilityQuestionsData } from '.';
import { LoanBreakdown, LoanTextSummary } from '../';
import {
  useApplicationId,
  useEligibilityQuestions,
  useLoanDetails,
} from '../../app';
import {
  Alert,
  ContentWrapper,
  CreditScoreUnaffected,
  Loading,
} from '../../components';
import { EligibilityTermsPageContent } from '../../pages/eligibility-terms-page';
import { PrivacyPageContent } from '../../pages/privacy-page/privacy-page';
import {
  Events,
  FormActions,
  FormNames,
  gtmTrackEvent,
} from '../../utils/gtm-track-event';
import {
  useAffordabilityData,
  useCheckEligibility,
  useLoanOfferData,
} from './queries';

export const ELIGIBILITY_ALERT_MESSAGE =
  'We’ve highlighted where something seems to be missing or incorrect. Could you take another look?';

interface EligibilityFormProps {
  onBack: (e: React.MouseEvent<HTMLElement>) => void;
  onSuccess: () => void;
  onLoading: (isLoading: boolean) => void;
}

const EligibilityForm: React.FC<EligibilityFormProps> = ({
  onBack,
  onSuccess,
  onLoading,
}) => {
  const theme = useTheme();
  const { applicationId } = useApplicationId();
  const loanCalculatorBgColor = whiten('brand.quaternary', 80)(theme);
  const [showErrorAlert, setShowErrorAlert] = React.useState(false);
  const pageErrorRef = React.useRef<HTMLDivElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalContent, setModalContent] = useState<
    React.ReactElement | undefined
  >();

  const {
    loanDetails: { amount, durationYears },
    setLoanDetails,
  } = useLoanDetails();

  const {
    mutate: checkEligibility,
    isSuccess: isCheckEligibilityRequestSuccessful,
    isLoading,
  } = useCheckEligibility(applicationId);

  const {
    eligibilityQuestions,
    setEligibilityQuestions,
    hasExistingData,
    setHasExistingData,
  } = useEligibilityQuestions();

  const {
    data: existingData,
    isSuccess: isExistingDataSuccess,
    isLoading: isExistingDataLoading,
  } = useAffordabilityData(applicationId);

  const { data: loanOfferData, isSuccess: isLoanOfferDataSuccess } =
    useLoanOfferData(applicationId);

  useEffect(() => {
    if (isExistingDataSuccess && Object.keys(existingData).length) {
      setEligibilityQuestions({
        ...existingData,
        dependants: String(existingData.dependants),
      });
      setHasExistingData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExistingDataSuccess, existingData]);

  useEffect(() => {
    if (isLoanOfferDataSuccess && Object.keys(loanOfferData).length) {
      setLoanDetails(loanOfferData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoanOfferDataSuccess, loanOfferData]);

  const handleChange = React.useCallback(
    (data, isValid) => {
      setEligibilityQuestions(data);

      if (isValid && showErrorAlert) {
        setShowErrorAlert(false);
      }
    },
    [showErrorAlert, setEligibilityQuestions]
  );

  useEffect(() => {
    onLoading(isLoading);
  }, [onLoading, isLoading]);

  const handleSubmitErrors = () => setShowErrorAlert(true);

  React.useEffect(() => {
    if (isCheckEligibilityRequestSuccessful) {
      onSuccess();
    }
  }, [isCheckEligibilityRequestSuccessful, onSuccess]);

  const formatEligibilityFields = (data: EligibilityQuestions) => {
    const numericFields = [
      'grossAnnualIncome',
      'dependants',
      'otherHouseholdIncome',
      'accommodationCosts',
      'councilTax',
      'expenditureUtilitiesCost',
      'expenditureEducationCost',
      'expenditureOtherCost',
    ];
    const formattedData = Object.entries(data).reduce(
      (formattedData, [field, val]) => {
        if (numericFields.includes(field)) {
          formattedData[field] = Number(val);
        } else {
          formattedData[field] = val;
        }
        return formattedData;
      },
      {}
    ) as Omit<EligibilityQuestions, 'employmentStartDate'>;

    // This part is necessary because acquisitions-api does not receive
    // council tax as a separate field.
    // For more details access (https://github.com/NewDayCards/NewDay.Web.UI/pull/386)
    formattedData['expenditureOtherCost'] =
      formattedData['expenditureOtherCost'] + formattedData['councilTax'];

    return formattedData;
  };

  const handleSubmit = (data: EligibilityQuestions) => {
    gtmTrackEvent({
      event: Events.FORM_CTA,
      form_name: FormNames.CHECK_ELIGIBILITY,
      form_action: FormActions.SUBMIT_FORM,
      link_text: 'check my eligibility',
    });

    const { employmentStartDate, ...eligibilityData } = data;

    const getEmploymentStartDate = () => {
      if (employmentStartDate) {
        const { month, year } = employmentStartDate;
        return {
          employmentStartDate: formatISO(
            new Date(Number(year), Number(month) - 1),
            { representation: 'date' }
          ),
        };
      }
      return {};
    };

    checkEligibility({
      ...formatEligibilityFields(eligibilityData),
      ...getEmploymentStartDate(),
      loanTerm: durationYears * 12,
      loanAmount: amount,
    });
  };

  const handleOnBack = (e) => {
    gtmTrackEvent({
      event: Events.FORM_CTA,
      form_name: FormNames.CHECK_ELIGIBILITY,
      form_action: FormActions.CLICK_CTA,
      link_text: 'back',
    });
    onBack(e);
  };

  React.useEffect(() => {
    if (showErrorAlert && pageErrorRef.current) {
      pageErrorRef.current.scrollIntoView();
    }
  }, [showErrorAlert]);

  return (
    <>
      <Form
        options={{
          defaultValues: eligibilityQuestions,
        }}
        onSubmit={handleSubmit}
        onChange={handleChange}
        onSubmitError={handleSubmitErrors}
        shouldResetDefaultValues={hasExistingData}
      >
        <div ref={pageErrorRef}>
          {showErrorAlert ? (
            <Box bg={loanCalculatorBgColor} px={8} pt={8} pb={2}>
              <ContentWrapper>
                <Alert
                  title="That doesn’t look right"
                  message={ELIGIBILITY_ALERT_MESSAGE}
                />
              </ContentWrapper>
            </Box>
          ) : null}
        </div>

        <FormSections
          formSections={eligibilityQuestionsData}
          contentWrapper={ContentWrapper}
        />

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay backgroundColor="rgba(0,0,0,0.9)" />
          <ModalContent
            borderRadius={0}
            maxWidth="full"
            w={{ base: 'xs', md: '2xl' }}
            p={{ base: 1, md: 4 }}
          >
            <ModalHeader>
              <ModalCloseButton
                backgroundColor="brand.primary"
                color="white"
                padding={6}
                borderRadius={0}
                top={0}
                right={0}
                _hover={{
                  backgroundColor: 'brand.primary',
                }}
              />
            </ModalHeader>
            <ModalBody>{modalContent}</ModalBody>
          </ModalContent>
        </Modal>

        <ContentWrapper>
          <Flex py={8} direction="column" bg="white">
            <Heading>Are your loan details correct?</Heading>
            <Divider
              my={2}
              borderTopWidth="1px"
              borderTopColor="brand.greyScale.400"
            />
            <LoanTextSummary />
            <LoanBreakdown verbose variant="dark" />
            <Text mt={8}>
              When you press &apos;check my eligibility&apos; below, you&apos;re
              telling us that you&apos;ve read and agree to our{' '}
              <Text
                as="button"
                fontWeight="bold"
                textDecoration="underline"
                onClick={(e) => {
                  onOpen();
                  e.preventDefault();
                  setModalContent(<EligibilityTermsPageContent />);
                }}
              >
                eligibility terms
              </Text>{' '}
              and{' '}
              <Text
                as="button"
                fontWeight="bold"
                textDecoration="underline"
                onClick={(e) => {
                  onOpen();
                  e.preventDefault();
                  setModalContent(<PrivacyPageContent />);
                }}
              >
                privacy notice
              </Text>
              {'.'}
            </Text>
            <SimpleGrid columns={{ base: 1, md: 2 }} gap={4} mt={8}>
              <Button type="submit">Check my eligibility</Button>
              <Button variant="tertiary" onClick={handleOnBack}>
                Back
              </Button>
            </SimpleGrid>
            <CreditScoreUnaffected mt={8} />
          </Flex>
        </ContentWrapper>
      </Form>
      <Loading
        isLoading={isExistingDataLoading}
        title="Please wait while we check for existing data..."
        secondsToLoad={20}
      />
    </>
  );
};

export { EligibilityForm };
