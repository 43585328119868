import { FeRoutes, LocalStorageTypes } from '@newday/plum-types';
import axios, { AxiosStatic } from 'axios';
import jwtDecode from 'jwt-decode';
import {
  createContext,
  memo,
  ReactNode,
  useContext,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from '../app/use-debounce';
import { useApplicationId } from './use-application-id';

export type ConfigProviderProps = {
  children: ReactNode;
};

type ConfiContextData = {
  axios: AxiosStatic;
};

const ConfigContext = createContext<ConfiContextData>({} as ConfiContextData);

const ConfigProvider = ({ children }: ConfigProviderProps) => {
  const navigate = useNavigate();
  const { applicationId } = useApplicationId();
  const debounceFn = useDebounce(300);

  axios.defaults.baseURL = process.env.PLUM_BFF_URI;

  axios.interceptors.request.use(async (config) => {
    const token = localStorage.getItem(LocalStorageTypes.BEARER);

    const initialConfigHeaders = config.headers;
    config.headers = {
      ...initialConfigHeaders,
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    };
    return config;
  });

  axios.interceptors.response.use(
    function (response) {
      if (response.headers?.['bearer']) {
        localStorage.setItem(
          LocalStorageTypes.BEARER,
          response.headers['bearer']
        );

        const event = new CustomEvent('bearer-refreshed', {
          detail: { token: jwtDecode(response.headers['bearer']) },
        });

        debounceFn(
          // fully tested via cypress
          /* istanbul ignore next */ () => {
            // istanbul ignore next
            document.dispatchEvent(event);
          }
        );
      }
      return response;
    },
    function (error: unknown) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        localStorage.clear();

        // The below if statement is covered by a cypress test. We agreed
        // that it wasn't valuable to test this via Jest.

        // istanbul ignore next
        if (location.pathname !== FeRoutes.saveAndReturn) {
          navigate(`${FeRoutes.saveAndReturn}?applicationId=${applicationId}`);
        }
      }

      return Promise.reject(error);
    }
  );

  return (
    <ConfigContext.Provider value={{ axios }}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => {
  return useContext(ConfigContext);
};

export default memo(ConfigProvider);
