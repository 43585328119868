import { Breakdown, BreakdownProps } from '../breakdown';
import {
  formatCurrency,
  formatCurrencyToTwoDecimalPlaces,
  formatPercentageByDecimalPlaces,
} from '../../utils';
import { useLoanBreakdown } from '../../shared/hooks/useLoanBreakdown';
import { useFeatureFlags } from '../../app';

export interface LoanBreakdownProps
  extends Omit<BreakdownProps, 'items' | 'itemsPerRow'> {
  apr: number;
  durationYears: number;
  isLoaded?: boolean;
  loanAmount: number;
  verbose?: boolean;
}

export const LoanBreakdown = ({
  apr,
  durationYears,
  isLoaded = true,
  loanAmount,
  variant,
  verbose = false,
  ...rest
}: LoanBreakdownProps) => {
  const { DYNAMIC_PRICING } = useFeatureFlags();
  const { totalAmount, monthlyPayment, aer } = useLoanBreakdown({
    loanAmount,
    durationYears,
    apr,
  });
  const yearOrYears = durationYears > 1 ? 'years' : 'year';

  const calculatedItems = [
    {
      content: isLoaded ? formatPercentageByDecimalPlaces(apr, 2) : undefined,
      label: 'APR representative',
    },
    {
      content: isLoaded
        ? formatCurrencyToTwoDecimalPlaces(monthlyPayment)
        : undefined,
      label: 'Monthly payment',
    },
    {
      content: isLoaded
        ? formatCurrencyToTwoDecimalPlaces(totalAmount)
        : undefined,
      label: 'Total amount payable',
    },
  ];

  if (DYNAMIC_PRICING) {
    calculatedItems.splice(2, 0, {
      content: isLoaded ? formatPercentageByDecimalPlaces(aer, 2) : undefined,
      label: 'Interest rate (pa fixed)',
    });
  }

  const selectedItems = [
    {
      content: formatCurrency(loanAmount),
      label: 'Loan amount',
    },
    {
      content: `${durationYears} ${yearOrYears}`,
      label: 'Loan term',
    },
  ];

  return (
    <Breakdown
      variant={variant}
      items={verbose ? [...selectedItems, ...calculatedItems] : calculatedItems}
      dynamicPricing={DYNAMIC_PRICING}
      {...rest}
    />
  );
};
