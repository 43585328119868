import type {
  AffordabilityResponse,
  EligibilityPostRequest,
  LoanOfferResponse,
} from '@newday/plum-types';
import { BffRoutes } from '@newday/plum-types';
import axios from 'axios';
import { useMutation, useQuery } from 'react-query';

export const useCheckEligibility = (applicationId: string) =>
  useMutation((eligibilityQuestions: EligibilityPostRequest) =>
    axios.post(
      BffRoutes.eligibility.replace(':applicationId', applicationId),
      eligibilityQuestions
    )
  );

export const useAffordabilityData = (applicationId: string) =>
  useQuery(
    ['plum', 'affordability', applicationId],
    () =>
      axios
        .get<AffordabilityResponse>(
          `${BffRoutes.affordability.replace(':applicationId', applicationId)}`
        )
        .then((response) => response.data),
    {
      cacheTime: 0,
    }
  );

export const useLoanOfferData = (applicationId: string) =>
  useQuery(
    ['plum', 'loanOffer', applicationId],
    () =>
      axios
        .get<LoanOfferResponse>(
          `${BffRoutes.loanOffer.replace(':applicationId', applicationId)}`
        )
        .then((response) => response.data),
    {
      cacheTime: 0,
    }
  );