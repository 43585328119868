import {
  Box,
  Button,
  Heading,
  Link,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Skeleton,
  Text,
  useDisclosure,
  useStyleConfig,
  VStack,
} from '@newday/core';
import { ExclamationMarkIcon } from '@newday/icons';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { useFiles } from '../../app/files-provider';
import { Loading } from '../loading';
import { DocUploadModal } from './doc-upload-modal';
import { FileList } from './file-list';
import { UploadArea } from './upload-area';

export interface DocUploadOptions {
  label: string;
  value: string;
  description: ReactElement;
  heading: string;
  modalCheck?: boolean;
  modalCheckMessage?: string;
}

export interface DocUploadProps {
  options: DocUploadOptions[];
  isLoadingOptions?: boolean;
}

export const DocUpload: React.FC<DocUploadProps> = ({
  options,
  isLoadingOptions,
}) => {
  const buttonStyles = useStyleConfig('RadioButton');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isCheckOpen,
    onOpen: onCheckOpen,
    onClose: onCheckClose,
  } = useDisclosure();

  const [selectedOption, setSelectedOption] = useState<DocUploadOptions>();
  const {
    files,
    handleAddFiles,
    handleDeleteFiles,
    handleDeleteFile,
    handleSubmitFiles,
    isSubmitLoading,
  } = useFiles();

  const handleSelection = useCallback(
    (selection: string) => {
      const option = options.find(({ value }) => value === selection);
      setSelectedOption(option);
    },
    [options],
  );

  const handleResetSelection = () => {
    handleDeleteFiles();
    setSelectedOption(undefined);
    onClose();
  };

  const handleSeeFullList = () => {
    const hasUnsubmittedFile = files?.find((file) => file.submitted === false);

    if (hasUnsubmittedFile) onOpen();
    else setSelectedOption(undefined);
  };

  const handleFilesUpload = (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ) => {
    /* istanbul ignore else */
    if (selectedOption) {
      const rejectedFiles = fileRejections.map(
        (rejectedFile: FileRejection) => ({
          ...rejectedFile,
          uuid: uuidv4(),
          type: selectedOption.value,
          submitted: false,
        }),
      );
      const successfulFiles: typeof files = acceptedFiles.map((file: File) => ({
        file,
        isLoading: true,
        uuid: uuidv4(),
        type: selectedOption.value,
        submitted: false,
      }));

      handleAddFiles([...rejectedFiles, ...successfulFiles]);
    }
  };

  const handleClick = () => {
    if (
      selectedOption?.value &&
      options
        .filter((option) => option.modalCheck)
        .map((optionToCheck) => optionToCheck.value)
        .includes(selectedOption.value)
    ) {
      return onCheckOpen();
    }

    handleSubmitFiles();
  };

  useEffect(() => {
    const unsubmittedFiles = files?.filter((file) => !file.submitted);

    if (unsubmittedFiles?.length && options.length) {
      handleSelection(unsubmittedFiles[0]?.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const disableSubmit =
    !files?.length ||
    files.find((file) => file.isLoading) !== undefined ||
    files.every(({ submitted }) => submitted === true);

  return (
    <>
      {selectedOption ? (
        <>
          <Link
            as="button"
            type="button"
            onClick={handleSeeFullList}
            mb={4}
            textDecorationColor="brand.tertiary"
            textUnderlineOffset={1}
            color="gray.800"
          >
            See full list of documents accepted +
          </Link>
          <DocUploadModal
            isOpen={isOpen}
            onClose={onClose}
            handleResetSelection={handleResetSelection}
          />
          <Box w="full">
            <Box
              backgroundColor="brand.primary"
              p={1}
              borderTopLeftRadius="md"
              borderTopRightRadius="md"
            >
              <Text textAlign="center" fontWeight="semibold" color="white">
                {selectedOption.heading}
              </Text>
            </Box>
            <Box backgroundColor="brand.greyScale.200" p={5}>
              <Box mb={5}>
                <Text fontWeight="bold" color="brand.primary" mb={1}>
                  Check before uploading
                </Text>
                <Box fontSize="sm">{selectedOption.description}</Box>
              </Box>
              <UploadArea onFilesUpload={handleFilesUpload} maxFileSize={15} />
              {!!files?.length && (
                <FileList files={files} handleDeleteFile={handleDeleteFile} />
              )}
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Text fontSize="sm" mb={4}>
            Select ONE of the following:
          </Text>
          <Skeleton
            data-testid="doc-upload-options-skeleton"
            isLoaded={!isLoadingOptions}
            width="full"
          >
            <SimpleGrid
              data-testid="doc-upload-options"
              columns={{ base: 1, md: 2 }}
              gap={2}
              w="full"
              role="group"
            >
              {options.map(({ label, value }) => (
                <Button
                  key={value}
                  __css={buttonStyles}
                  onClick={() => {
                    handleSelection(value);
                  }}
                >
                  {label}
                </Button>
              ))}
            </SimpleGrid>
          </Skeleton>
        </>
      )}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="full"
        mt={14}
      >
        <Button
          width={{ base: 'full', md: '2xs' }}
          onClick={handleClick}
          disabled={disableSubmit}
        >
          Submit now
        </Button>
      </Box>
      <Loading
        isLoading={isSubmitLoading}
        title="Please wait while your documents are submitted"
        secondsToLoad={20}
      />
      <Modal isOpen={isCheckOpen} onClose={onCheckClose} size="sm">
        <ModalOverlay />
        <ModalContent textAlign="center" padding={2}>
          <ModalHeader>
            <ExclamationMarkIcon />
            <Heading mt={3}>
              {
                options.find(({ value }) => value === selectedOption?.value)
                  ?.modalCheckMessage
              }
            </Heading>
          </ModalHeader>
          <ModalFooter>
            <VStack w="full" px={4}>
              <Button w="full" onClick={() => handleSubmitFiles()}>
                Yes, continue
              </Button>
              <Button variant="tertiary" w="full" onClick={onCheckClose}>
                No, go back
              </Button>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
