import {
  AffordabilityResponse,
  ApplicationReference,
  AprAndRiskGroup,
  BankDetailsResponse,
  BankDetailStatuses,
  DecisionAndApplyResponse,
  DecisionAndApplyStatuses,
  DefaultRouteMock,
  EligibilityGetResponse,
  EmploymentStatus,
  EmploymentStatusResponse,
  FeatureToggles,
  FeRoutes,
  LoanOfferResponse,
  PlumRedirectResponse,
  Proof,
  RepaymentInformation,
  RouteMocks,
  UpdateAddressResponse,
  UserDetails,
} from '@newday/plum-types';
import {
  getMockHtmlDocument,
  getMockUploadProof,
  UploadProofResponse,
} from '@newday/acquisitions-api-client';
import { getMockBffAffordability } from './affordability';
import { getMockApplicationReference } from './application-reference';
import { getMockAprAndRiskGroup } from './apr-and-risk-group';
import { getMockBankDetailsResponse } from './bank-details';
import { getMockFeatureToggles } from './feature-toggles';
import { getMockProduct } from './product';
import { getMockProof } from './proof';
import { getMockRepaymentInformation } from './repayment-information';
import { getMockUserDetails } from './user-details';
import { getMockEmploymentStatus } from './employment-status';

export const aprAndRiskGroupMocks: DefaultRouteMock<AprAndRiskGroup> = {
  default: {
    body: getMockAprAndRiskGroup(),
    statusCode: 200,
  },
};

export const userDetailsMocks: DefaultRouteMock<UserDetails> = {
  default: {
    body: getMockUserDetails(),
    statusCode: 200,
  },
};

export const initialRouteMocks: DefaultRouteMock<{}> = {
  default: {
    body: {},
    statusCode: 200,
  },
};

export const updateAddressMocks: DefaultRouteMock<UpdateAddressResponse> = {
  default: {
    body: 200,
    statusCode: 200,
  },
};

export type EligibilityGetMocksKeys = 'downsell' | 'ineligible';

export const eligibilityGetMocks: RouteMocks<
  EligibilityGetMocksKeys,
  EligibilityGetResponse
> = {
  default: {
    body: {
      product: getMockProduct(),
    },
    statusCode: 200,
  },
  downsell: {
    body: {
      product: getMockProduct({
        term: 5,
        monthlyPayment: 135.12,
        totalAmount: 8107,
      }),
    },
    statusCode: 200,
  },
  ineligible: {
    body: {},
    statusCode: 200,
  },
};

export type FeatureToggleMocksKeys =
  | 'default'
  | 'mouseflowOn'

export const featureToggleMocks: RouteMocks<
  FeatureToggleMocksKeys,
  FeatureToggles
> = {
  default: {
    body: getMockFeatureToggles(),
    statusCode: 200,
  },
  mouseflowOn: {
    body: getMockFeatureToggles({
      MOUSEFLOW: true,
    }),
    statusCode: 200,
  },
};

export type RedirectMocksKeys = 'downsell' | 'ineligible' | 'landing';

export const redirectMocks: RouteMocks<
  RedirectMocksKeys,
  PlumRedirectResponse
> = {
  default: {
    body: {
      redirect: FeRoutes.quoteEligible,
    },
    statusCode: 200,
  },
  downsell: {
    body: {
      redirect: FeRoutes.quoteCounterOffer,
    },
    statusCode: 200,
  },
  ineligible: {
    body: {
      redirect: FeRoutes.quoteIneligible,
    },
    statusCode: 200,
  },
  landing: {
    body: {
      redirect: FeRoutes.landingPage,
    },
    statusCode: 200,
  },
};

export const eligibilityPostMocks: DefaultRouteMock<{}> = {
  default: {
    body: {},
    statusCode: 200,
  },
};

const mockHtmlDocument = getMockHtmlDocument();
export const htmlDocumentsMocks: DefaultRouteMock<{ data: string }[]> = {
  default: {
    body: [
      { data: mockHtmlDocument },
      { data: mockHtmlDocument },
      { data: mockHtmlDocument },
    ],
    statusCode: 200,
  },
};

export type DecisionMocksKeys = 'declined';

export const decisionMocks: RouteMocks<
  DecisionMocksKeys,
  DecisionAndApplyResponse
> = {
  default: {
    body: {
      decision: DecisionAndApplyStatuses.Accepted,
    },
    statusCode: 200,
  },
  declined: {
    body: {
      decision: DecisionAndApplyStatuses.Declined,
    },
    statusCode: 200,
  },
};

export const applicationReferenceMocks: DefaultRouteMock<ApplicationReference> =
{
  default: {
    body: getMockApplicationReference(),
    statusCode: 200,
  },
};

export type BankDetailsMocksKeys = 'generalRefer' | 'retryBankValidation';

export const bankDetailsMocks: RouteMocks<
  BankDetailsMocksKeys,
  BankDetailsResponse
> = {
  default: {
    body: getMockBankDetailsResponse(),
    statusCode: 200,
  },
  generalRefer: {
    body: getMockBankDetailsResponse({
      status: BankDetailStatuses.referGeneral,
    }),
    statusCode: 200,
  },
  retryBankValidation: {
    body: getMockBankDetailsResponse({
      status: BankDetailStatuses.retryBankValidation,
    }),
    statusCode: 200,
  },
};

export const repaymentInformationMocks: DefaultRouteMock<RepaymentInformation> =
{
  default: {
    body: getMockRepaymentInformation(),
    statusCode: 200,
  },
};

export const saveAndReturnMocks: DefaultRouteMock<{}> = {
  default: {
    body: {},
    statusCode: 200,
  },
};

export const uploadProof: DefaultRouteMock<UploadProofResponse> = {
  default: {
    body: getMockUploadProof(),
    statusCode: 201,
  },
};

export const proofList: DefaultRouteMock<Array<Proof>> = {
  default: {
    body: [
      getMockProof(),
      getMockProof({ id: '321', fileName: 'p60.pdf', type: 'p60' }),
    ],
    statusCode: 200,
  },
};

export const deleteAllProofs: DefaultRouteMock<{}> = {
  default: {
    body: {},
    statusCode: 204,
  },
};

export const submitProofs: DefaultRouteMock<{}> = {
  default: {
    body: {},
    statusCode: 204,
  },
};

export type AffordabilityMocksKeys = 'withAffordabilityResponse';

export const affordabilityMocks: RouteMocks<
  AffordabilityMocksKeys,
  AffordabilityResponse
> = {
  default: {
    body: {},
    statusCode: 200,
  },
  withAffordabilityResponse: {
    body: getMockBffAffordability({
      employmentStatus: EmploymentStatus.selfEmployed,
      employmentStartDate: {
        month: '10',
        year: '2019',
      },
    }),
    statusCode: 200,
  },
};

export const loanOfferMocks: DefaultRouteMock<LoanOfferResponse> = {
  default: {
    body: {},
    statusCode: 200,
  },
};

export const employmentStatusMocks: DefaultRouteMock<EmploymentStatusResponse> =
{
  default: {
    body: getMockEmploymentStatus(),
    statusCode: 200,
  },
};

export type BffMiddleWareMockKeys =
  | keyof typeof aprAndRiskGroupMocks
  | keyof typeof applicationReferenceMocks
  | keyof typeof bankDetailsMocks
  | keyof typeof decisionMocks
  | keyof typeof eligibilityGetMocks
  | keyof typeof eligibilityPostMocks
  | keyof typeof employmentStatusMocks
  | keyof typeof featureToggleMocks
  | keyof typeof htmlDocumentsMocks
  | keyof typeof loanOfferMocks
  | keyof typeof redirectMocks
  | keyof typeof repaymentInformationMocks
  | keyof typeof initialRouteMocks
  | keyof typeof saveAndReturnMocks
  | keyof typeof submitProofs
  | keyof typeof updateAddressMocks
  | keyof typeof uploadProof
  | keyof typeof proofList
  | keyof typeof deleteAllProofs
  | keyof typeof affordabilityMocks