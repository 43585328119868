/* eslint-disable @typescript-eslint/no-empty-function */
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { BrandThemeProvider } from '@newday/core';
import {
  FeRoutes,
  FeRouteTitles,
  PlumSupportedBrand,
} from '@newday/plum-types';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import { OptimizelyTogglesProvider, PAGES, PUBLIC_ROUTES } from '.';
import { Loading } from '../components';
import { DevTool } from '../components/dev-tool';
import { globalStyles, plumTheme } from '../components/theme';
import { SessionTimeout } from '../features/session-timeout/session-timeout';
import { useGtmVirtualPageEvent } from '../hooks';
import { ErrorPage } from '../pages/error-page';
import { useInitialRoute } from '../shared/queries';
import { reactPlugin } from '../utils/app-insights';
import AuthContextProvider, { AuthContext } from './auth-provider';
import { BrandProvider } from './brand-provider';
import ConfigProvider from './config-provider';
import ErrorBoundary from './error-boundary';
import { FontLoader } from './font-loader';
import { ReactQueryClientProvider } from './query-provider';
import { ScrollToTop } from './scroll-to-top';
import { ApplicationIdProvider, useApplicationId } from './use-application-id';
import { EligibilityQuestionsProvider } from './use-eligibility-questions';
import { FeatureFlagsProvider, useFeatureFlags } from './use-feature-flags';
import { LoanDetailsProvider } from './use-loan-details';
import './use-mouseflow/mouseflow';
import useMouseflow from './use-mouseflow/use-mouseflow';
import { SelectedAddressIdProvider } from './use-selected-address-id';

type Props = {
  basename?: string;
  isMobileView?: boolean;
  brand: PlumSupportedBrand;
};

const isSaveAndReturnUrl = () => {
  const searchParams = new URLSearchParams(location.search);

  return (
    location.pathname === FeRoutes.saveAndReturn &&
    searchParams.has('applicationId')
  );
};

const isPublicRoute = () =>
  PUBLIC_ROUTES.includes(location.pathname as FeRoutes) || isSaveAndReturnUrl();

export const AppRoutes = () => {
  useMouseflow();
  const { MAINTENANCE_MODE } = useFeatureFlags();
  const { authenticated, isLoading, isAuthenticatedStateSet } =
    useContext(AuthContext);
  const { applicationId } = useApplicationId();
  const navigate = useNavigate();
  const [isInitialRedirectRequired, setIsInitialRedirectRequired] = useState(
    !isPublicRoute()
  );

  useGtmVirtualPageEvent();

  const {
    data: redirectData,
    isSuccess: isRedirectSuccess,
    isLoading: isRedirectLoading,
  } = useInitialRoute(
    applicationId,
    authenticated && isInitialRedirectRequired && !!applicationId,
    'initial-route',
    location.pathname
  );

  const redirectPath = redirectData?.data;

  // istanbul ignore next
  useEffect(() => {
    if (isInitialRedirectRequired && isRedirectSuccess) {
      setIsInitialRedirectRequired(false);

      if (redirectPath) {
        navigate(redirectPath);
      }
    }
  }, [isRedirectSuccess, redirectPath, navigate, isInitialRedirectRequired]);

  if (MAINTENANCE_MODE) {
    return (
      <Routes>
        <Route
          path="/*"
          element={
            <ErrorPage
              title="Sorry, we'll be back soon"
              subtitle="We're currently making an update to our personal loans application journey, which means the service is temporarily unavailable.<br/><br/>We expect to be back up and running in the next 2 weeks, so please check back then."
            />
          }
        />
      </Routes>
    );
  }

  if (isLoading || isRedirectLoading) {
    return (
      <Loading
        isLoading={isLoading}
        title="Please wait while loading..."
        secondsToLoad={10}
      />
    );
  }

  return isAuthenticatedStateSet &&
    !authenticated &&
    !MAINTENANCE_MODE &&
    !isPublicRoute() ? (
    <Routes>
      <Route
        path="/*"
        element={
          <ErrorPage
            title="You are not authenticated"
            subtitle="Please go back to the App and try again"
          />
        }
      />
    </Routes>
  ) : (
    <Routes>
      <Route path="/" element={<Navigate to={FeRoutes.landingPage} />} />
      {PAGES.map(({ path, Component, title }) => (
        <Route
          key={path}
          path={path}
          element={
            <>
              <Helmet>
                <title>{title}</title>
              </Helmet>
              <Component />
            </>
          }
        />
      ))}
    </Routes>
  );
};

const OptionalDevTool = ({ children }: { children: React.ReactNode }) => {
  const isDevToolEnabled = process.env.PLUM_DEV_TOOL_ENABLED === 'true';

  return isDevToolEnabled ? (
    <DevTool>{children}</DevTool>
  ) : (
    <>{children}</>
  );
};

// We ignore the App itself because these providers are created in our test-utils file
/* istanbul ignore next */
const App = ({ basename = '/', brand }: Props): ReactElement => {
  // this is necessary to include ico in the webpack build
  require(`../../public/${brand}.ico`);
  return (
    <>
      <Helmet>
        <title>{FeRouteTitles.landingPage}</title>
        <link
          id="favicon"
          rel="icon"
          href={`./assets/favicon/${brand}.ico`}
          type="image/x-icon"
        />
        <meta
          httpEquiv="Content-Security-Policy"
          content={process.env.PLUM_CONTENT_SECURITY_POLICY}
        />
      </Helmet>
      <AppInsightsContext.Provider value={reactPlugin}>
        <BrandThemeProvider
          brand={brand || 'aqua'}
          themes={[plumTheme]}
          globalStyles={globalStyles}
        >
          <ReactQueryClientProvider>
            <BrandProvider brand={brand}>
              <BrowserRouter basename={basename}>
                <ErrorBoundary>
                  <FontLoader>
                    <ScrollToTop>
                      <ApplicationIdProvider>
                        <ConfigProvider>
                          <SessionTimeout>
                            <AuthContextProvider>
                              <OptionalDevTool>
                                <EligibilityQuestionsProvider>
                                  <LoanDetailsProvider>
                                    <SelectedAddressIdProvider>
                                      <FeatureFlagsProvider>
                                        <OptimizelyTogglesProvider>
                                          <AppRoutes />
                                        </OptimizelyTogglesProvider>
                                      </FeatureFlagsProvider>
                                    </SelectedAddressIdProvider>
                                  </LoanDetailsProvider>
                                </EligibilityQuestionsProvider>
                              </OptionalDevTool>
                            </AuthContextProvider>
                          </SessionTimeout>
                        </ConfigProvider>
                      </ApplicationIdProvider>
                    </ScrollToTop>
                  </FontLoader>
                </ErrorBoundary>
              </BrowserRouter>
            </BrandProvider>
          </ReactQueryClientProvider>
        </BrandThemeProvider>
      </AppInsightsContext.Provider>
    </>
  );
};

export { App };
