import { FeatureToggles } from '@newday/plum-types';

export const getMockFeatureToggles = (
  overrides?: Partial<FeatureToggles>,
): FeatureToggles => ({
  MAINTENANCE_MODE: false,
  MOUSEFLOW: false,
  DYNAMIC_PRICING: false,
  ...overrides,
});
