import { FeatureToggles } from '@newday/plum-types';
import React from 'react';
import { useFeatureFlags } from '../app';

export const withFeatureToggle = (featureToggle: keyof FeatureToggles) => {
  return function <T>(
    ComponentIfFeatureToggleOn: React.ComponentType<T>,
    AlternativeComponent?: React.ComponentType<T>
  ) {
    const ComponentToReturn = (props: T) => {
      const featureToggles = useFeatureFlags();

      if (featureToggles[featureToggle]) {
        return <ComponentIfFeatureToggleOn {...props} />;
      }

      if (AlternativeComponent) {
        return <AlternativeComponent {...props} />;
      }

      return null;
    };

    ComponentToReturn.displayName = `withFeatureToggle(${featureToggle})`;

    return ComponentToReturn;
  };
};
