import { ErrorResponse, HttpErrorMessages } from '@newday/plum-types';
import { AxiosError } from 'axios';
import { ReactNode, ReactElement } from 'react';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientConfig,
  QueryClientProvider,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const isPlumError = (data: ErrorResponse): data is ErrorResponse => {
  return data && 'errorMessage' in data;
};

const isAxiosErrorResponse = (data: unknown): data is AxiosError<ErrorResponse> => {
  return (data as AxiosError<ErrorResponse>).response !== undefined;
};

const cacheConfig = {
  onError: (error: AxiosError<ErrorResponse> | unknown) => {
    if (
      isAxiosErrorResponse(error) &&
      error.response &&
      isPlumError(error.response.data)
    ) {
      const { data } = error.response;

      Object.assign(error, {
        title: data.error,
        subtitle: data.errorMessage,
      });

      return;
    }

    Object.assign(error as AxiosError<ErrorResponse>, {
      title: HttpErrorMessages[500].error,
      subtitle: HttpErrorMessages[500].errorMessage,
    });
  },
};

export const queryOptions: QueryClientConfig = {
  queryCache: new QueryCache(cacheConfig),
  mutationCache: new MutationCache(cacheConfig),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
    },
    mutations: {
      useErrorBoundary: true,
    },
  },
};

const queryClient = new QueryClient(queryOptions);

interface QueryProviderProps {
  children: ReactNode | ReactNode[];
}

// We ignore this provider in our jest tests because we create a new
// query client in each test using our test utils
// The error boundary logic for the app itself is still covered via cypress tests
// so this is still properly tested
/* istanbul ignore next */
export const ReactQueryClientProvider = ({
  children,
}: QueryProviderProps): ReactElement => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
