/* istanbul ignore file */
import type { PlumSupportedBrand } from '@newday/plum-types';
import { useEffect } from 'react';
import { useBrand } from '../brand-provider';
import { useFeatureFlags } from '../use-feature-flags';
import injectMouseflowScript from './mouseflow';

const useMouseflow = () => {
  const brand = useBrand().brand as PlumSupportedBrand;

  const { MOUSEFLOW } = useFeatureFlags();

  const mouseflowIds = {
    aqua: process.env.PLUM_MOUSEFLOW_AQUA,
    bip: process.env.PLUM_MOUSEFLOW_BIP,
    fluid: process.env.PLUM_MOUSEFLOW_FLUID,
    marbles: process.env.PLUM_MOUSEFLOW_MARBLES,
    opus: process.env.PLUM_MOUSEFLOW_OPUS,
    pulse: process.env.PLUM_MOUSEFLOW_PULSE,
    pulsepersonal: process.env.PLUM_MOUSEFLOW_PULSEPERSONAL,
  };

  const mouseflow_id = mouseflowIds[brand];

  useEffect(() => {
    if (MOUSEFLOW && mouseflow_id) {
      injectMouseflowScript(mouseflow_id);
    }
  }, [MOUSEFLOW, brand, mouseflow_id]);
};

export default useMouseflow;
