export enum EmploymentStatus {
  fullTime = 'fullTime',
  partTime = 'partTime',
  selfEmployed = 'selfEmployed',
  contractor = 'contractor',
  temporary = 'temporary',
  retired = 'retired',
  homemaker = 'homemaker',
  disability = 'disability',
  allowance = 'allowance',
  independentMeans = 'independentMeans',
  student = 'student',
  unemployed = 'unemployed',
  other = 'other',
}

export enum MaritalStatus {
  married = 'married',
  single = 'single',
  divorced = 'divorcedOrDissolvedCivilPartnership',
  widowed = 'widowed',
  cohabiting = 'cohabiting',
  toBeMarried = 'toBeMarried',
  separated = 'separated',
  civilPartnership = 'civilPartnership',
  other = 'other',
}

export enum DependantsCount {
  none = '0',
  one = '1',
  two = '2',
  threeOrMore = '3',
}

export enum ResidentialStatus {
  ownerOccupier = 'ownerOccupier',
  jointOwner = 'jointOwner',
  tenantFurnished = 'tenantFurnished',
  tenantUnfurnished = 'tenantUnfurnished',
  councilHousing = 'councilHousing',
  studentHousing = 'studentHousing',
  livingWithParents = 'livingWithParents',
  otherTenant = 'otherTenant',
  other = 'other',
}

export enum FinancialSituation {
  improve = 'improve',
  staySame = 'staySame',
  decline = 'decline',
}
export enum GeneralQuestions {
  employmentStatus = 'employmentStatus',
  grossAnnualIncome = 'grossAnnualIncome',
  employmentStartDate = 'employmentStartDate',
  maritalStatus = 'maritalStatus',
  dependants = 'dependants',
  // TODO: otherHouseholdIncome - conditional: married / cohabiting
  otherHouseholdIncome = 'otherHouseholdIncome',

  residentialStatus = 'residentialStatus',
  accommodationCosts = 'accommodationCosts',
  councilTax = 'councilTax',

  expenditureUtilitiesCost = 'expenditureUtilitiesCost',
  expenditureEducationCost = 'expenditureEducationCost',
  expenditureOtherCost = 'expenditureOtherCost',

  financialSituation = 'financialSituation',
}

export interface EligibilityQuestions {
  [GeneralQuestions.employmentStatus]: EmploymentStatus;
  [GeneralQuestions.grossAnnualIncome]: number;
  [GeneralQuestions.employmentStartDate]?: string;

  [GeneralQuestions.maritalStatus]: MaritalStatus;
  [GeneralQuestions.dependants]: DependantsCount;
  [GeneralQuestions.otherHouseholdIncome]?: number;

  [GeneralQuestions.residentialStatus]: ResidentialStatus;
  [GeneralQuestions.accommodationCosts]: number;
  [GeneralQuestions.councilTax]: number;

  [GeneralQuestions.expenditureUtilitiesCost]?: number;
  [GeneralQuestions.expenditureEducationCost]?: number;
  [GeneralQuestions.expenditureOtherCost]?: number;

  [GeneralQuestions.financialSituation]: FinancialSituation;
}

export type FrontEligibilityQuestions = Omit<
  EligibilityQuestions,
  'employmentStartDate'
> & {
  employmentStartDate?: { month: string; year: string };
};
